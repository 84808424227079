import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { fetchData } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import moment from "moment";

const ViewRoster = () => {
  const param = useParams();

  const navigate = useNavigate();
  const [rosterData, setRosterData] = useState({
    email_id: "",
    name: "",
    pickup_location: "",
    drop_location: "",
    pickup_time: "",
    dropoff_time: "",
    status: "",
    actual_pickup_time: "",
    actual_dropoff_time: "",
    past_scheduled_dates:[],
    upcoming_scheduled_dates:[],
    rider_roster_assignments: {
      trip_id: "",
    },
  });
  useEffect(() => {
    const fetchDataForRosterId = async () => {
      try {
        const rosterData = await fetchData(`admin/roster/${param?.slug}`);

        setRosterData(rosterData.roster);
       
      } catch (error) {
        // Handle errors
      }
    };

    fetchDataForRosterId();
  }, []);

  const navigateToUserlist = () => {
    navigate("/schedule-rides");
  };

  return (
    <>
      <Header />

      <Box m="20px">
        <Box>
          <h3 className="page-title">View Roster</h3>
        </Box>
        <Card className="add_card">
      <div style={{marginTop:"10px"}}>
        <h6>View Roster Details:</h6>
        <Table striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Trip Id</th>
                <th>Rider's Name</th>
                <th>Pickup Address</th>
                <th>Pickup Time</th>
                <th>Drop Address</th>
                <th>DropOff Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(rosterData.rider_roster_assignments) &&
                rosterData.rider_roster_assignments.map(
                  (tripIdItem: any, index: number) => {
                    return (
                      <>
                        <tr
                          className="review-roster-table-body"
                          key={index}
                          style={{ margin: "10px 0" }}
                        >
                          <td>{tripIdItem?.trip_id}</td>
                          <td>{tripIdItem?.name}</td>
                          <td>{tripIdItem?.pickup_location}</td>
                          <td>
                            {moment(tripIdItem?.pickup_time).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>

                          <td>{tripIdItem?.drop_location}</td>
                          <td>
                            {moment(tripIdItem?.dropoff_time).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>

                          <td>{tripIdItem?.status}</td>
                        </tr>
                      </>
                    );
                  }
                )}
            </tbody>
          </Table>
      </div>
         <div style={{marginTop:"50px"}}>
          <h6>
            View Past & Upcoming rides:
          </h6>
         <Table striped bordered hover size="lg" style={{borderColor: "black"}}>
            <thead>
              <tr>
                <th>Past Scheduled Dates</th>
                <th>Upcoming Scheduled Dates</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{rosterData.past_scheduled_dates.join(", ")}</td>
                <td>{rosterData.upcoming_scheduled_dates.join(", ")}</td>
              </tr>
            </tbody>
          </Table>
         </div>
        </Card>
        <div style={{ marginTop: "50px", marginLeft: "30px" }}>
          <button
            type="button"
            className="btn "
            style={{
              width: "15%",
              backgroundColor: "lightgray",
              marginLeft: "10px",
            }}
            onClick={navigateToUserlist}
          >
            Go Back To Roster
          </button>
        </div>
      </Box>
    </>
  );
};

export default ViewRoster;
