/** @format */

import React, { useEffect, useState } from "react";
import "./AddUser.css";
import Header from "../../components/header/Header";
import axios from "../../api/axios";
import AsyncSelect from "react-select/async";
import { fetchData } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import { useError } from "../../components/utils/error/ErrorContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const BASE_URL = "https://api.gods-eye.in/api";

const AddUser = () => {
  const navigate = useNavigate();
  const { showError } = useError();

  const [locationData, setLocationData] = useState<any>();
  const [selectedImage, setSelectedImage] = useState();
  const [imagePreview, setImagePreview] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());

  const [formData, setFormData] = useState({
    email_id: "abc2@gmail.com",
    mobile: "7974103505",
    name: "Sourabh Majumdar",
    user_type: "RIDER",
    city: "Bangalore",
    emergency_contact: "2342311929",
    home_address: " ",
    work_address: " ",
    employee_id: "ESRI-12311",
    image: selectedImage ?? null,
    longitude: "",
    latitude: "",
    organization: "GOOGLE",
    transport_date: "",
    transport_status: "",
  });

  useEffect(() => {
    const hostname = window.location.hostname;
    const organizationOptions =
      hostname === "esri.gods-eye.in" ? ["ESRI"] : ["DAFTAR", "ESRI"];
    setFormData((prevFormData) => ({
      ...prevFormData,
      organization: organizationOptions[0], // set the default organization
    }));
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      let responseData;
      const token = localStorage.getItem("token_info");
      if (!formData.transport_date) {
        // If transport date is not selected by the user, set it to today's date
        formData.transport_date = new Date().toISOString();
      }
      if (selectedImage) {
        const formDataObject = new FormData();
        formDataObject.append("email_id", formData.email_id);
        formDataObject.append("mobile", formData.mobile);
        formDataObject.append("name", formData.name);
        formDataObject.append("user_type", formData.user_type);
        formDataObject.append("city", formData.city);
        formDataObject.append("emergency_contact", formData.emergency_contact);
        formDataObject.append("home_address", formData.home_address);
        formDataObject.append("work_address", formData.work_address);
        formDataObject.append("employee_id", formData.employee_id);
        formDataObject.append("longitude", formData.longitude);
        formDataObject.append("latitude", formData.latitude);
        formDataObject.append("image", selectedImage);
        formDataObject.append("organization", formData.organization);
        formDataObject.append("transport_date", formData.transport_date);
        formDataObject.append("transport_status", formData.transport_status);

        // Send request with multipart/form-data content type

        const response = await axios.post(
          `${BASE_URL}/user/admin/create`,
          formDataObject,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        responseData = response.data;
      } else {
        const response = await axios.post(
          `${BASE_URL}/user/admin/create`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        responseData = response.data;
      }
      toast.success("User added successfully", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    } catch (error: any) {
      if (error.isAxiosError && error.response) {
        // Axios error, handle it with status code and error message
        const statusCode = error.response.status;
        const errorMsg = error.response.data.error;
        toast.error(`HTTP Error ${statusCode}: ${errorMsg || "Unknown error"}`);
      } else {
        toast.error(`An error occurred: ${error.message || "Unknown error"}`);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Check if the input is the mobile field and the entered value is not exactly 10 digits
    if (name === "mobile" && value.length > 10) {
      // Show an error message or handle the error as needed
      showError("Mobile number should have exactly 10 digits");
      return; // Do not update the state further
    }

    if (name === "emergency_contact" && value.length > 10) {
      // Show an error message or handle the error as needed
      showError("Emergency number should have exactly 10 digits");
      return; // Do not update the state further
    }

    setFormData({ ...formData, [name]: value });
  };

  const fetchDataAndUpdateState = async (inputValue?: any) => {
    try {
      let data;

      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setLocationData(data?.data);
      setFormData({
        ...formData,
        image: data ? data?.profile_picture : "",
      });
      setImagePreview(data?.profile_picture);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataAndUpdateState();
  }, [formData.home_address, formData.work_address]);

  const loadOptions = async (inputValue: string) => {
    if (inputValue) {
      await fetchDataAndUpdateState(inputValue);
      const options = locationData.map((location: any) => ({
        value: location.formatted_address,
        label: location.formatted_address,
        location: location.location, // Keep the original location data for future use
      }));
      return options;
    } else {
      return [];
    }
  };

  const handleLocationChange = (id: string, selectedOption: any) => {
    if (id === "work") {
      setFormData({
        ...formData,
        work_address: selectedOption ? selectedOption.label : "",
      });
    } else if (id === "home") {
      setFormData({
        ...formData,
        home_address: selectedOption ? selectedOption.label : "",
        longitude: selectedOption?.location?.lng ?? "",
        latitude: selectedOption?.location?.lat ?? "",
      });
    }
  };

  useEffect(() => {
    if (locationData) {
    }
  }, [locationData]);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      // Validate file type
      if (!file.type.startsWith("image/")) {
        // Display error message for invalid file type
        showError("Only images are allowed. Please select an image file.");

        // Clear the file input value
        e.target.value = "";

        return; // Exit function early
      }

      // Validate image dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const maxWidth = 250;
        const maxHeight = 250;
        const minWidth = 15;
        const minHeight = 50;
        const maxSizeMB = 5; // Maximum file size allowed in MB

        if (
          img.width <= maxWidth &&
          img.height <= maxHeight &&
          img.width >= minWidth &&
          img.height >= minHeight &&
          file.size / (1024 * 1024) <= maxSizeMB
        ) {
          // Set selectedImage state
          setSelectedImage(file);
          setFormData({
            ...formData,
            image: file,
          });

          // Display image preview
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          // Display error message for invalid dimensions
          showError(
            `Please upload an image with dimensions between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} and size less than ${maxSizeMB}MB`
          );

          // Clear the file input value
          e.target.value = "";

          // Clear selectedImage and imagePreview in state
          setSelectedImage(undefined);
          setImagePreview(null);
        }
      };
    }
  };

  const handleRemoveImage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSelectedImage(undefined); // Pass undefined instead of null
    setImagePreview(null);

    // Clear the file input value
    const fileInput = document.getElementById("imageInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ""; // Set the value to an empty string to clear the input
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value, // Use dynamic key to update the corresponding field
    }));
  };

  const navigateToUserlist = () => {
    navigate("/users");
  };

  return (
    <>
      <Header />

      {
        <Box m="20px">
          <Box>
            <h3 className="page-title">Create New User</h3>
          </Box>
          <Card className="add_card">
            <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="name"
                      placeholder="Enter your name"
                      onChange={handleInputChange}
                      className="new_form_control"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="email_id"
                      placeholder="Enter Email Id"
                      onChange={handleInputChange}
                      className="new_form_control"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="mobile"
                      placeholder="Enter Phone Number"
                      onChange={handleInputChange}
                      className="new_form_control"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Emergency Phone Number:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="emergency_contact"
                      placeholder="Enter Emergency Phone Number"
                      onChange={handleInputChange}
                      className="new_form_control"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Employee_id</Form.Label>
                    <Form.Control
                      type="text"
                      name="employee_id"
                      required
                      placeholder="Enter Employee Id"
                      onChange={handleInputChange}
                      className="new_form_control"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Organization Name:</Form.Label>
                    <Form.Select
                      name="organization"
                      aria-label="Default select example"
                      onChange={handleSelectChange}
                    >
                      <option>Select Organization</option>

                      <option value="DAFTAR">DAFTAR</option>
                      <option value="ESRI">ESRI</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Transport Status</Form.Label>
                    <Form.Select
                      name="transport_status"
                      aria-label="Default select example"
                      onChange={handleSelectChange}
                    >
                      <option>Select Status</option>
                      <option value="PENDING">PENDING</option>
                      <option value="OPTED">OPTED</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 transport_date">
                    <Form.Label>Transport Date:</Form.Label>
                    <DatePicker
                      className="tranport_datepicker_input"
                      selected={startDate}
                      onChange={(date: any) => {
                        setStartDate(date);
                        setFormData({
                          ...formData,
                          transport_date: date,
                        });
                      }}
                      minDate={new Date()} // Set minDate to today's date
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Image:</Form.Label>
                <Form.Control
                  type="file"
                  accept="images/*"
                  id="imageInput"
                  onChange={handleImageChange}
                />
              </Form.Group>
              {imagePreview && (
                <div
                  style={{ position: "relative" }}
                  onClick={(e) => {
                    handleRemoveImage(e);
                  }}
                >
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      height: "100px",
                      width: "100px",
                      margin: "10px",
                    }}
                  />
                  <CloseIcon
                    fontSize="small"
                    color="warning"
                    style={{
                      position: "absolute",
                      left: "99px",
                    }}
                  />
                </div>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Address(Work):</Form.Label>
                <AsyncSelect
                  className="work_input"
                  cacheOptions
                  id="work"
                  placeholder="Select Work Address"
                  defaultOptions
                  loadOptions={(inputValue) => loadOptions(inputValue)}
                  onChange={(selectedOption) =>
                    handleLocationChange("work", selectedOption)
                  }
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Address(Home):</Form.Label>
                    <AsyncSelect
                      cacheOptions
                      id="home"
                      placeholder="Select Home Address"
                      defaultOptions
                      loadOptions={(inputValue) => loadOptions(inputValue)}
                      onChange={(selectedOption) =>
                        handleLocationChange("home", selectedOption)
                      }
                      className="home_input"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Longitude:</Form.Label>
                    <Form.Control
                      type="text"
                      className="new_form_control"
                      name="longitude"
                      value={formData?.longitude}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Latitude:</Form.Label>
                    <Form.Control
                      type="text"
                      className="new_form_control"
                      value={formData?.latitude}
                      name="latitude"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div>
                <button
                  style={{ width: "90px" }}
                  type="submit"
                  className="btn btn-success"
                >
                  Save
                </button>

                <button
                  type="button"
                  className="btn "
                  style={{
                    width: "90px",
                    backgroundColor: "lightgray",
                    marginLeft: "10px",
                  }}
                  onClick={navigateToUserlist}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Card>
        </Box>
      }
    </>
  );
};

export default AddUser;
