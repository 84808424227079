import React, { isValidElement, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import TimePicker from "react-time-picker";
import { DatePicker, Stack } from "rsuite";
import { FaCalendar, FaClock } from "react-icons/fa";
// import TimePicker from "rsuite";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "./create_ride.css";
import { fetchData } from "../../../api/apiService";
import { Row, Col } from "react-bootstrap";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../components/utils/Validations";

interface User {
  name: string;
  email: string;
}

interface StepTwoProps {
  userData: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  users: any[];
  setUsers: React.Dispatch<React.SetStateAction<any[]>>;
  onNext: () => void;
  handlePrev: () => void;
  stepTwoData: any;
  setStepTwoData: any;
  stepOneUserDetails: any;
  newUsers: any;
  setNewUsers: any;
}

const UserProgressIndicator: React.FC<{ users: User[] }> = ({ users }) => {
  const totalSteps = 3;

  const completedSteps = users.length;
  const remainingSteps = totalSteps - completedSteps;

  const renderProgressSteps = () => {
    const steps = [];
    for (let i = 1; i <= totalSteps; i++) {
      if (i <= completedSteps) {
        steps.push(
          <div key={i} className="completed-step">
            Step {i}
          </div>
        );
      } else {
        steps.push(
          <div key={i} className="remaining-step">
            Step {i}
          </div>
        );
      }
    }
    return steps;
  };

  return (
    <div className="progress-indicator">
      <h3>User Progress</h3>
      <div className="progress-steps">{renderProgressSteps()}</div>
      <p>
        {completedSteps} user(s) added. {remainingSteps} step(s) remaining.
      </p>
    </div>
  );
};

const StepTwo: React.FC<StepTwoProps> = ({
  userData,
  setUserData,
  users,
  setUsers,
  onNext,
  handlePrev,
  stepTwoData,
  setStepTwoData,
  stepOneUserDetails,
  newUsers,
  setNewUsers,
}) => {
  const [time, setTime] = useState("10:00");
  const [tempState, setTempState] = useState([{}]);
  const [mobile, setMobile] = useState("");

  const [locationData, setLocationData] = useState<any>();
  const [pickupLocationData, setPickupLocationData] = useState<any>();
  const [dropoffLocationData, setDropoffLocationData] = useState<any>();

  const validatePhoneNumber = (phone: string): boolean => {
    const pattern = /^\d{10,11}$/;
    return pattern.test(phone);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedUsers = [...newUsers];
    const currentUser = updatedUsers[index];
    updatedUsers[index] = {
      ...currentUser,
      [name]: name === "email_id" || name === "mobile" ? value.trim() : value,
    };
    setNewUsers(updatedUsers);
    if (
      name === "mobile" &&
      value.trim() !== "" &&
      validatePhoneNumber(value)
    ) {
      fetchDataAndUpdateState("RIDER", value.trim(), "mobile", index);
    }

    //todo
    // else if (name === "email_id" && validateEmail(value)) {
    //   fetchDataAndUpdateState("RIDER", value.trim(), "email_id", index);
    // }
  };

  const handleTimeChange = (value: any, index: any, type: any) => {
    const formattedTime = value;
    const updatedUsers = [...newUsers];
    if (type === "pickup") {
      updatedUsers[index] = {
        ...updatedUsers[index],
        pickup_time: formattedTime,
      };
    }

    if (type === "dropoff") {
      updatedUsers[index] = {
        ...updatedUsers[index],
        dropoff_time: formattedTime,
      };
    }

    setNewUsers(updatedUsers);
  };

  const handleAddUser = async () => {
    try {
      const userData = stepTwoData;
      if (userData) {
        const newUserDetails = {
          rider_user_id: "",
          pickup_location: "",
          dropoff_location: "",
          pickup_time: "",
          dropoff_time: "",
          email_id: " ",
          mobile: " ",
          name: " ",
          organization: " ",
        };

        setNewUsers([...newUsers, newUserDetails]);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  interface User {
    rider_user_id: string;
    pickup_location: string;
    dropoff_location: string;
    pickup_time: string;
    dropoff_time: string;
    email_id: string;
    mobile: string;
    name: string;
    organization: string;
  }

  const HandleNext = () => {
    const incompleteUsers = newUsers.filter(
      (user: User) =>
        !user.pickup_location ||
        !user.dropoff_location ||
        !user.pickup_time ||
        !user.dropoff_time
    );

    if (incompleteUsers.length > 0) {
      alert(
        "Please fill in all pickup/dropoff locations and times for each user."
      );
      return;
    }

    const updatedUsers = newUsers.map((user: User, index: number) => {
      return {
        ...user,
        rider_user_id: newUsers[index].rider_user_id,
        pickup_location: newUsers[index]?.pickup_location || "",
        dropoff_location: newUsers[index]?.dropoff_location || "",
      };
    });

    setUserData({
      ...userData,
      users: updatedUsers,
    });

    onNext();
  };

  const fetchDataAndUpdateState = async (
    type: string,
    value: string,
    searchType: string,
    index: number
  ) => {
    try {
      const data = await fetchData(
        `user/admin/search?user_type=RIDER&${searchType}=${value}`
      );
      if (type === "RIDER") {
        const updatedUsers = [...newUsers];
        const currentUser = updatedUsers[index];

        if (data && data.user) {
          const { email_id, name, mobile, id } = data.user;

          updatedUsers[index] = {
            ...currentUser,
            email_id: email_id || currentUser.email_id,
            name: name || currentUser.name,
            mobile: mobile || currentUser.mobile,
            rider_user_id: id || currentUser?.rider_user_id,
          };
        }

        setNewUsers(updatedUsers);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataAndUpdateStatePickup = async (inputValue?: any) => {
    try {
      let data;
      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setPickupLocationData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataAndUpdateStateDropoff = async (inputValue?: any) => {
    try {
      let data;
      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setDropoffLocationData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   fetchDataAndUpdateStatePickup();
  // }, [formData.home_address]);

  // useEffect(() => {
  //   fetchDataAndUpdateStateDropoff();
  // }, [formData.work_address]);

  const loadOptions = async (inputValue: string, id: string) => {
    let locationData =
      id === "pickup" ? pickupLocationData : dropoffLocationData;

    if (!locationData) {
      // Fetch location data if not available
      await (id === "pickup"
        ? fetchDataAndUpdateStatePickup(inputValue)
        : fetchDataAndUpdateStateDropoff(inputValue));
      locationData = id === "pickup" ? pickupLocationData : dropoffLocationData;
    }

    const options = locationData.map((location: any) => ({
      value: location.formatted_address,
      label: location.formatted_address,
      location: location.location,
    }));

    return options;
  };

  const handleLocationChange = (
    id: string,
    selectedOption: any,
    index: number
  ) => {
    const locationData =
      id === "pickup" ? pickupLocationData : dropoffLocationData;

    const updatedUsers = [...newUsers];
    const currentUser = updatedUsers[index];

    if (id === "pickup") {
      updatedUsers[index] = {
        ...currentUser,
        pickup_location: selectedOption ? selectedOption.label : "",
      };
    } else if (id === "dropoff") {
      updatedUsers[index] = {
        ...currentUser,
        dropoff_location: selectedOption ? selectedOption.label : "",
      };
    }

    setNewUsers(updatedUsers);
  };

  console.log("newUsers__", newUsers);

  console.log("userData__", userData);
  console.log("time__", time);
  console.log("StepTwoData", stepTwoData);
  console.log("stepOneUserDetails__", stepOneUserDetails);

  return (
    <div className="roster-main">
      <p className="step_two_title">Pickup User:</p>
      <div
        className="roster-input-div-main"
        style={{ display: "flex", gap: "10px" }}
      >
        <div className="progress-bar">
          {newUsers &&
            newUsers?.map((user: any, index: number) => (
              <React.Fragment key={index}>
                <div
                  className={`dot ${
                    index === users.length - 1 ? "active" : ""
                  }`}
                >
                  {index + 1}
                </div>
                {index !== users.length - 1 && <div className="line" />}
              </React.Fragment>
            ))}
        </div>
        <div style={{ width: "90%" }}>
          {newUsers &&
            newUsers?.map((newUser: any, index: number) => {
              return (
                <>
                  <Row>
                    <Col>
                      <div className="roster-input-div">
                        <p>Name:</p>
                        <input
                          type="text"
                          name="name"
                          value={newUser?.name}
                          onChange={(e) => handleInputChange(e, index)}
                          placeholder="Name"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="roster-input-div">
                        <p>Phone :</p>
                        <input
                          type="text"
                          name="mobile"
                          value={newUser?.mobile}
                          onChange={(e) => handleInputChange(e, index)}
                          placeholder="Phone Number"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="roster-input-div">
                        <p>Email :</p>
                        <input
                          type="text"
                          name="email_id"
                          value={newUser?.email_id}
                          onChange={(e) => handleInputChange(e, index)}
                          placeholder="Email Address"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* <div className="roster-input-div">
                    <p>Email :</p>
                    <input
                      type="text"
                      name="email_id"
                      value={newUser?.email_id}
                      onChange={(e) => handleInputChange(e, index)}
                      placeholder="Email Address"
                    />
                    </div> */}

                  <div className="roster-input-div">
                    {/* <input
                      type="text"
                      name="pickup_location"
                      value={newUser?.pickup_location}
                      onChange={(e) => handleInputChange(e, index)}
                      placeholder="Pickup Location"
                    /> */}
                    <Row>
                      <Col>
                        <div>
                          <p style={{ margin: "6px 0" }}>Pickup location :</p>
                          <AsyncSelect
                            className="step_two_input"
                            cacheOptions
                            required
                            id="pickup_location"
                            defaultOptions
                            loadOptions={(inputValue) =>
                              loadOptions(inputValue, "pickup")
                            }
                            onChange={(selectedOption) =>
                              handleLocationChange(
                                "pickup",
                                selectedOption,
                                index
                              )
                            }
                            onInputChange={(inputValue) =>
                              fetchDataAndUpdateStatePickup(inputValue)
                            }
                          />
                        </div>
                      </Col>
                      {/* <input
                      type="text"
                      name="dropoff_location"
                      value={newUser?.dropoff_location}
                      onChange={(e) => handleInputChange(e, index)}
                      placeholder="Drop Location"
                    /> */}
                      <Col>
                        <div>
                          <p style={{ margin: "6px 0" }}>Drop Location :</p>
                          <AsyncSelect
                            className="step_two_input"
                            cacheOptions
                            required
                            id="dropoff"
                            defaultOptions
                            loadOptions={(inputValue) =>
                              loadOptions(inputValue, "dropoff")
                            }
                            onChange={(selectedOption) =>
                              handleLocationChange(
                                "dropoff",
                                selectedOption,
                                index
                              )
                            }
                            onInputChange={(inputValue) =>
                              fetchDataAndUpdateStateDropoff(inputValue)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* <input
                      type="text"
                      name="pickup_time"
                      value={newUser?.pickup_time}
                      onChange={(e) => handleInputChange(e, index)}
                      placeholder="Pickup Time"
                    /> */}
                  <Row>
                    <Col>
                      <div className="roster-input-div">
                        <p style={{ margin: "8px 0" }}>Pickup Time :</p>
                        <DatePicker
                          name="pickup_time"
                          value={newUser?.pickup_time}
                          onChange={(e: any) =>
                            handleTimeChange(e, index, "pickup")
                          }
                          format="HH:mm:ss"
                          caretAs={FaClock}
                          placeholder="Pickup Time"
                          style={{ width: "80%" }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="roster-input-div">
                        <p style={{ margin: "7px 0" }}>Drop Time :</p>
                        <DatePicker
                          name="dropoff_time"
                          value={newUser?.dropoff_time}
                          onChange={(e: any) =>
                            handleTimeChange(e, index, "dropoff")
                          }
                          format="HH:mm:ss"
                          caretAs={FaClock}
                          placeholder="Drop Time"
                          style={{ width: "80%" }}
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* <input
                      type="text"
                      name="dropoff_time"
                      value={newUser?.dropoff_time}
                      onChange={(e) => handleInputChange(e, index)}
                      placeholder="Drop Time"
                    /> */}
                  {/* <TimePicker
                  id="pickup_time"
                  name="pickup_time"
                  value={newUser.pickup_time}
                  onChange={(e:any)=>handleInputChange(e,index)}
                /> */}

                  <hr
                    style={{
                      // height: 10,
                      border: "1px solid black",
                    }}
                  />
                </>
              );
            })}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        {" "}
        <button
          onClick={handleAddUser}
          disabled={newUsers?.length >= 5}
          style={{ width: "15%", marginLeft: "10px" }}
          className="btn btn-success"
        >
          Add New User
        </button>
        <button
          onClick={HandleNext}
          className="Button-Custom"
          style={{ width: "10%", marginLeft: "10px" }}
        >
          Next
        </button>
        <button
          onClick={handlePrev}
          style={{
            width: "10%",
            backgroundColor: "lightgray",
            marginLeft: "10px",
          }}
          className="btn "
        >
          Previous
        </button>
      </div>{" "}
    </div>
  );
};

export default StepTwo;
