import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../schedule/createRide/create_ride.css";
import { fetchData } from "../../api/apiService";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../components/utils/Validations";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

interface StepOneProps {
  userData: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  onNext: () => void;
  handlePrev: () => void;
  userDetails: any;
  setUserDetails: any;
  chauffeurDetails: any;
  setChauffeurDetails: any;
  setStepOneUserDetails: any;
  setShownDetails: any;
  shownDetails: any;
  setNewUsers: any;
  stepOneUserDetails: any;
}

const AdhocRoster: React.FC<StepOneProps> = ({
  userData,
  setUserData,
  onNext,
  handlePrev,
  userDetails,
  setUserDetails,
  chauffeurDetails,
  setChauffeurDetails,
  setStepOneUserDetails,
  shownDetails,
  setShownDetails,
  setNewUsers,
  stepOneUserDetails,
}) => {
  const [stepOneUserData, setStepOneUserData] = useState<any>();
  const [stepOneChauffeurData, setStepOneChauffeurData] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());

  const param = useParams();
  console.log("param", param);
  const [chauffeurData, setChauffeurData] = useState({
    user_id:"",
    email_id: "",
    name: "",
    mobile: "",
    actual_pickup_time: "",
    actual_dropoff_time: "",
    past_scheduled_dates: [],
    upcoming_scheduled_dates: [],
    rider_roster_assignments: {
      trip_id: "",
    },
    vehicle: {
      license_plate: "",
    },
  });

  useEffect(() => {
    const fetchDataForChauffeur = async () => {
      try {
        const chauffeurData = await fetchData(`user/admin/${param?.slug}`);
        setChauffeurData(chauffeurData.data);
        console.log("dataaaa=====>", chauffeurData.data);
      } catch (error) {
        // Handle errors
      }
    };

    fetchDataForChauffeur();
  }, []);

  const handleNext = () => {
    setUserData({
      ...userData,
      users: {
        rider_user_id: stepOneUserData?.id,
      },
      chauffeur_user_id: chauffeurData?.user_id,
      chauffeur: chauffeurData,
    });
    // setUserDetails(stepOneUserData, stepOneChauffeurData);
    setStepOneUserDetails([
      {
        ...setStepOneUserData,
        users: [
          {
            rider_user_id: stepOneUserData?.id,
            email_id: stepOneUserData?.email_id,
            name: stepOneUserData?.name,
            mobile: stepOneUserData?.mobile,
            organization: stepOneUserData?.employee_id,
          },
        ],
        dates_list: [startDate],
        chauffeur_user_id: chauffeurData?.user_id,
      },
    ]);
    setShownDetails([
      ...shownDetails,
      {
        users: userDetails,
        chauffeur: chauffeurDetails,
      },
    ]);

    setNewUsers(stepOneUserDetails[0]?.users);

    onNext();
  };

  console.log('chafferid', chauffeurData?.user_id)

  const handleDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleChauffeurDetailsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setChauffeurDetails({ ...chauffeurDetails, [name]: value });
  };

  const fetchDataAndUpdateMobileState = async (
    type: string,
    details: any,
    keyWord: any
  ) => {
    try {
      const data = await fetchData(
        `user/admin/search?user_type=${type}&mobile=${keyWord}`
      );
      if (type === "RIDER") {
        setStepOneUserData(data?.user);
        setUserDetails({ ...userDetails, email: data?.user?.email_id });
      } else if (type === "CHAUFFEUR") {
        setStepOneChauffeurData(data?.user);
        setChauffeurDetails({
          ...chauffeurDetails,
          email: data?.user?.email_id,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataAndUpdateEmailState = async (
    type: string,
    details: any,
    keyWord: any
  ) => {
    try {
      const data = await fetchData(
        `user/admin/search?user_type=${type}&email_id=${keyWord}`
      );
      if (type === "RIDER") {
        setStepOneUserData(data?.user);
        setUserDetails({ ...userDetails, mobile: data?.user?.mobile });
      } else if (type === "CHAUFFEUR") {
        setStepOneChauffeurData(data?.user);
        setChauffeurDetails({
          ...chauffeurDetails,
          mobile: data?.user?.mobile,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (validatePhoneNumber(userDetails?.mobile)) {
      fetchDataAndUpdateMobileState("RIDER", userDetails, userDetails?.mobile);
    }
    if (validatePhoneNumber(chauffeurDetails?.mobile)) {
      fetchDataAndUpdateMobileState(
        "CHAUFFEUR",
        chauffeurDetails,
        chauffeurDetails?.mobile
      );
    }
  }, [userDetails?.mobile, chauffeurDetails?.mobile]);

  useEffect(() => {
    if (validateEmail(userDetails?.email)) {
      fetchDataAndUpdateEmailState("RIDER", userDetails, userDetails?.email);
    }
    if (validateEmail(chauffeurDetails?.email)) {
      fetchDataAndUpdateEmailState(
        "CHAUFFEUR",
        chauffeurDetails,
        chauffeurDetails?.email
      );
    }
  }, [userDetails?.email, chauffeurDetails?.email]);

  return (
    <div className="roster-main">
      <div className="roster-input-div-main">
        <h6 className="pragraph-heading">User Details</h6>
        <Row>
          <Col>
            <div className="roster-input-div">
              <p>Name :</p>
              <input
                type="text"
                name="Name"
                value={stepOneUserData?.name}
                placeholder="Name"
              />
            </div>
            <div className="roster-input-div">
              <p>Phone :</p>
              <input
                type="text"
                name="mobile"
                value={userDetails?.mobile}
                onChange={handleDetailsChange}
                placeholder="Phone Number"
              />
            </div>
          </Col>
          <Col>
            <div className="roster-input-div">
              <p>Email :</p>
              <input
                type="text"
                name="email"
                value={userDetails?.email}
                onChange={handleDetailsChange}
                placeholder="Email Address"
              />
            </div>
            <div className="roster-input-div">
              <p>Organization :</p>
              <input
                type="text"
                name="mobile"
                value={stepOneUserData?.employee_id}
                placeholder="Organization"
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="roster-input-div-main">
        <p className="pragraph-heading">Chauffeur Details</p>
        <Row>
          <Col>
            <div className="roster-input-div">
              <p>Name :</p>
              <input
                type="text"
                name="Name"
                value={chauffeurData?.name}
                placeholder="Name"
              />
            </div>
            <div className="roster-input-div">
              <p>Phone :</p>
              <input
                type="text"
                name="mobile"
                value={chauffeurData?.mobile}
                onChange={handleChauffeurDetailsChange}
                placeholder="Phone Number"
              />
            </div>
          </Col>
          <Col>
            <div className="roster-input-div">
              <p>Email :</p>
              <input
                type="text"
                name="email"
                value={chauffeurData?.email_id}
                onChange={handleChauffeurDetailsChange}
                placeholder="Email Address"
              />
            </div>
            <div className="roster-input-div">
              <p>Vehicle Number :</p>
              <input
                type="text"
                name="vehicle"
                value={chauffeurData?.vehicle?.license_plate || ""}
                placeholder="Vehicle Number"
                // disabled
                // onChange={handleChauffeurDetailsChange}
              />
            </div>
          </Col>
        </Row>
        <div className="roster-input-div">
          <p style={{ marginTop: "7px" }}>Date :</p>
          <DatePicker
            className="datepicker_input"
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
          />
        </div>
      </div>

      <div>
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn "
            style={{
              width: "90px",
              backgroundColor: "lightgray",
            }}
            disabled
            onClick={handlePrev}
          >
            Previous
          </button>
          <button
            style={{ width: "90px", marginLeft: "10px" }}
            onClick={handleNext}
            disabled={!stepOneUserData} // Check if user details are filled
            type="submit"
            className="btn btn-success"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdhocRoster;
