import React from 'react'
import { useNavigate } from 'react-router-dom'
function NotFound() {
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/'); // Replace '/' with the URL of your dashboard
  };
   
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Oops! Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <button onClick={handleBackToDashboard}>Back to Dashboard</button>
    </div>
  )
}

export default NotFound