import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "./Utils";

const PrivateRoutes = () => {
  let auth = getCookie("token_info");
  // let auth = true;
  let loggedIn = localStorage.getItem("loggedIn");
  return loggedIn || auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
