/** @format */

import React, { useEffect, useState } from "react";
import "./reports.css";
import { DatePicker, Stack, Modal, Dropdown } from "rsuite";
import Loader from "../../components/utils/Loader";
import Header from "../../components/header/Header";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { fetchData, PutData } from "../../api/apiService";
import { FaEdit } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { IoMdEye } from "react-icons/io";
import { Dialog } from "primereact/dialog";
import { Row, Col } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";

function Reports() {
  const [loading, setLoading] = useState(true);
  const [reportsData, setReportsData] = useState<any>();
  const [data, setData] = useState<any>();
  const [searchInput, setSearchInput] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(0);
  const [tripId, setTripId] = useState<any>("");
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [actualBoardingTime, setActualBoardingTime] = useState<any>("");
  const [dropOffTime, setDropOffTime] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [date, setDate] = useState<any>(moment().format("YYYY-MM-DD"));

  const navigate = useNavigate();

  const handleOpen = (tripId: any) => {
    const selectedRoster = reportsData?.flattenedRosters.find((roster: any) =>
      roster?.rider_roster_assignments.some(
        (user: any) => user.trip_id === tripId
      )
    );

    if (selectedRoster) {
      const isDeleted = selectedRoster.rider_roster_assignments.some(
        (user: any) => user.status === "DELETED"
      );

      if (isDeleted) {
        toast.error("This roster is already deleted and cannot be edited");
      } else {
        setTripId(tripId);
        setVisible(true);
      }
    }
  };

  const fetchDataAndUpdateState = async (page: number) => {
    try {
      setLoading(true);
      let data;
      const pageSize = 10;
      const pageNumber = page + 1;
      data = await fetchData(
        `admin/roster/getAllReports?page=${pageNumber}&pageSize=${pageSize}`
      );
      setReportsData(data?.reports);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDataAndUpdateState(currentPage);
  }, [searchInput, currentPage]);

  const fetchCSVData = async (e: any) => {
    if (e) {
      const dateObject = new Date(e);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const day = String(dateObject.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      console.log("Formatted date:", formattedDate);
      try {
        let response = await fetchData(
          `admin/roster/report/download?date=${formattedDate}`
        );
        console.log("response", response);
        if (
          response &&
          response.includes(
            '"roster_id","unique_id","scheduled_date","created_at"'
          )
        ) {
          setData(data);
          setLoading(false);
          downloadFile(response, formattedDate);
        } else {
          console.log("response", response);
          console.log("Data not found for this date");
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);
        setLoading(false);
        if (error.response && error.response.status === 404) {
          toast.error("No data found for this date");
        }
      }
    }
  };

  // useEffect(() => {
  //   if (date) {
  //     fetchCSVData(date);
  //   }
  // }, []);

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  const saveButtonHandler = async () => {
    try {
      let ApiPayload = {
        actual_pickup_time: actualBoardingTime,
        actual_dropoff_time: dropOffTime,
        status: status,
      };
      await PutData(`admin/roster/report/${tripId}`, ApiPayload);
      setVisible(false);
      fetchDataAndUpdateState(currentPage);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const modalTimeHandler = (e: any) => {
    setActualBoardingTime(e);
  };
  const modalDropOffTimeHandler = (date: any) => {
    setDropOffTime(date);
  };

  const handleDropdownSelect = (value: any, event: any) => {
    setStatus(value);
  };

  useEffect(() => {}, [actualBoardingTime]);

  const downloadFile = (csvData: any, selectedDate: string) => {
    const formattedDate = selectedDate.replaceAll("-", "_");
    const filename = `reports_of_${formattedDate}.csv`;

    const url = window.URL.createObjectURL(new Blob([csvData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <Header />

      <div className="schedule-main">
        <div className="button-div">
          <div>
            <h6 className="reports">Reports of Roster</h6>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <h6
              style={{
                marginBottom: "10px",
                color: "black",
                fontWeight: "500",
                marginTop: "6px",
              }}
            >
              Choose Date To Export CSV :
            </h6>
            <DatePicker
              format="yyyy-MM-dd"
              // value={date}
              // onChange={async (newDate) => {
              //   setDate(newDate); // Update the date state
              //   await fetchCSVData(newDate); // Call fetchCSVData with the new date
              //   downloadFile(); // Download the CSV file
              // }}
              onOk={fetchCSVData}
              style={{ marginRight: "30px", marginLeft: "3px" }}
            />
          </div>
        </div>
        <hr />
        {loading ? (
          <Loader />
        ) : (
          <>
            <table className="schedule-table table-no-border">
              <thead>
                <tr className="table-head">
                  <th>Sr. No</th>
                  <th>Trip ID</th>
                  <th>Chauffeur info</th>
                  <th>Schedule</th>
                  <th>Boarding time</th>
                  <th>DropOff time</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <>
                {loading ? (
                  <Loader />
                ) : (
                  <tbody>
                    {Array.isArray(reportsData?.flattenedRosters) &&
                      reportsData?.flattenedRosters.map(
                        (report: any, index: number) => {
                          const serialNumber = index + 1 + currentPage * 10;
                          return (
                            <>
                              <tr
                                className="table-body"
                                key={index}
                                style={{ margin: "10px 0" }}
                              >
                                <td style={{ paddingBottom: "10px" }}>
                                  {serialNumber}
                                </td>
                                <td>
                                  {report?.rider_roster_assignments?.map(
                                    (user: any) => {
                                      return (
                                        <>
                                          {user?.trip_id || " "}
                                          <br />
                                          <br />
                                        </>
                                      );
                                    }
                                  )}
                                </td>

                                <td>
                                  {report?.chauffeur?.name}
                                  <br />
                                  {report?.chauffeur?.mobile}
                                  <br />
                                  {/* {report?.chauffeur?.email_id} */}
                                  <br />
                                </td>
                                <td>
                                  {report?.rider_roster_assignments?.map(
                                    (user: any) => {
                                      return (
                                        <>
                                          {moment(user?.pickup_time).format(
                                            "DD-MM-YYYY"
                                          ) || " "}
                                          <br />
                                          <br />
                                        </>
                                      );
                                    }
                                  )}{" "}
                                </td>
                                <td>
                                  {report?.rider_roster_assignments?.map(
                                    (user: any) => {
                                      return (
                                        <>
                                          {user?.actual_pickup_time
                                            ? moment(
                                                user?.actual_pickup_time
                                              ).format("YYYY-MM-DD HH:mm:ss")
                                            : " "}
                                          <br />
                                          <br />
                                        </>
                                      );
                                    }
                                  )}{" "}
                                </td>
                                <td>
                                  {report?.rider_roster_assignments?.map(
                                    (user: any) => {
                                      return (
                                        <>
                                          {user?.actual_pickup_time
                                            ? moment(
                                                user?.actual_dropoff_time
                                              ).format("YYYY-MM-DD HH:mm:ss")
                                            : " "}
                                          <br />
                                          <br />
                                        </>
                                      );
                                    }
                                  )}{" "}
                                </td>
                                <td>
                                  {report?.rider_roster_assignments?.map(
                                    (user: any) => {
                                      let color;
                                      let fontWeight;
                                      let fontSize;
                                      switch (user?.status) {
                                        case "NO_SHOW":
                                          color = "red";
                                          break;
                                        case "PENDING":
                                          color = "gold";
                                          fontWeight = "800";
                                          fontSize = "1.2em";

                                          break;
                                        case "COMPLETED":
                                          color = "green";
                                          break;
                                        case "PICKUP":
                                          color = "purple";
                                          break;
                                        default:
                                          color = "black";
                                      }
                                      return (
                                        <>
                                          <span style={{ color }}>
                                            {user?.status}
                                            <br />
                                            <br />
                                          </span>
                                        </>
                                      );
                                    }
                                  )}
                                </td>
                                {window.location.hostname ===
                                  "www.gods-eye.in" ||
                                window.location.hostname === "localhost" ? (
                                  <td>
                                    {report?.rider_roster_assignments?.map(
                                      (user: any) => {
                                        return (
                                          <>
                                            <Tooltip title="View Roster">
                                              <IconButton>
                                                <IoMdEye
                                                  style={{
                                                    height: "25px",
                                                    width: "25px",
                                                    marginRight: "10px",
                                                    color: "grey",
                                                  }}
                                                  onClick={() =>
                                                    navigate(
                                                      `/viewReport/${user?.trip_id}`
                                                    )
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit Roster">
                                              <IconButton>
                                                <FaEdit
                                                  style={{
                                                    height: "23px",
                                                    width: "23px",
                                                    marginRight: "10px",
                                                    color: "grey",
                                                  }}
                                                  onClick={() =>
                                                    handleOpen(user?.trip_id)
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <br />
                                            <br />
                                          </>
                                        );
                                      }
                                    )}{" "}
                                  </td>
                                ) : (
                                  <>
                                    <td>
                                      {report?.rider_roster_assignments?.map(
                                        (user: any) => {
                                          return (
                                            <>
                                              <Tooltip title="View Roster">
                                                <IconButton>
                                                  <IoMdEye
                                                    style={{
                                                      height: "25px",
                                                      width: "25px",
                                                      marginRight: "10px",
                                                      color: "grey",
                                                    }}
                                                    onClick={() =>
                                                      navigate(
                                                        `/viewReport/${user?.trip_id}`
                                                      )
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>

                                              <br />
                                              <br />
                                            </>
                                          );
                                        }
                                      )}{" "}
                                    </td>
                                  </>
                                )}
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                )}
              </>
            </table>
          </>
        )}
      </div>
      {/* <CustomModal isOpen={open} onClose={handleClose}>
        <form onSubmit={saveButtonHandler}>
          <div style={{ margin: "20px" }}>
            <h2 style={{ marginBottom: "10px" }}>Edit Roaster</h2>
            <span
              className="input-container-add-user"
              style={{ marginBottom: "10px" }}
            >
              <p>Boarding time</p>
              <DatePicker
                format="MM/dd/yyyy HH:mm"
                value={actualBoardingTime}
                onChange={modalTimeHandler}
              />
            </span>{" "}
            <span
              className="input-container-add-user"
              style={{ marginBottom: "10px" }}
            >
              <p>DropOff time</p>
              <DatePicker
                format="MM/dd/yyyy HH:mm"
                value={dropOffTime}
                onChange={modalDropOffTimeHandler}
              />
            </span>{" "}
            <span
              className="input-container-add-user"
              style={{ marginBottom: "10px" }}
            >
              <p>Status</p>
              <Dropdown
                title={`Selected: ${status}`}
                onSelect={handleDropdownSelect}
                trigger="click"
              >
                <Dropdown.Item eventKey="NO_SHOW">No Show</Dropdown.Item>
              
              </Dropdown>
            </span>{" "}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "5px 20px",
            }}
          >
            <Button
              color="green"
              appearance="primary"
              onClick={saveButtonHandler}
            >
              Save
            </Button>
          </div>
        </form>
      </CustomModal> */}

      {/* <div className="card flex justify-content-center"> */}
      {/* <Button
          label="Show"
          icon="pi pi-external-link"
          onClick={() => setVisible(true)}
        /> */}
      <Dialog
        // header="Edit Roster"
        visible={visible}
        style={{ width: "40vw", height: "50vh" }}
        onHide={() => setVisible(false)}
        // footer={footerContent}
        className="custom-modal"
      >
        <form onSubmit={saveButtonHandler}>
          <div style={{ margin: "20px" }}>
            <h2 style={{ marginBottom: "10px" }}>Edit Report</h2>
            <Row>
              <Col>
                <div
                  className="input-container-add-user"
                  style={{ marginBottom: "25px" }}
                >
                  <Col>
                    <h6 style={{ marginBottom: "10px", color: "black" }}>
                      Boarding time :
                    </h6>

                    <DatePicker
                      // style={{margin:"2px 0"}}
                      format="MM/dd/yyyy HH:mm"
                      value={actualBoardingTime}
                      onChange={modalTimeHandler}
                    />
                  </Col>
                </div>{" "}
              </Col>
              <Col>
                <div
                  className="input-container-add-user"
                  style={{ marginBottom: "40px", color: "black" }}
                >
                  <Col>
                    <h6 style={{ marginBottom: "10px", color: "black" }}>
                      DropOff time :
                    </h6>
                    <DatePicker
                      format="MM/dd/yyyy HH:mm"
                      value={dropOffTime}
                      onChange={modalDropOffTimeHandler}
                    />
                  </Col>
                </div>{" "}
              </Col>
            </Row>

            <h6
              style={{ marginTop: "20px", marginLeft: "15px", color: "black" }}
            >
              Status
            </h6>
            <Dropdown
              style={{ margin: "8px", width: "200px" }}
              title={`Selected: ${status}`}
              onSelect={handleDropdownSelect}
              trigger="click"
            >
              <Dropdown.Item eventKey="PENDING">PENDING</Dropdown.Item>
              <Dropdown.Item eventKey="NO_SHOW">NO SHOW</Dropdown.Item>
              <Dropdown.Item eventKey="COMPLETED">COMPLETED</Dropdown.Item>
              <Dropdown.Item eventKey="PICKUP">PICKUP</Dropdown.Item>
            </Dropdown>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              bottom: "5px",
              right: "5px",
            }}
          >
            {/* <Button
                color="green"
                //  appearance="primary"
                onClick={saveButtonHandler}
              >
                Save
              </Button> */}
            <button
              type="button"
              style={{ backgroundColor: "#419043", borderRadius: "5px" }}
              onClick={saveButtonHandler}
              className="btn btn-success"
            >
              Success
            </button>
          </div>
        </form>
      </Dialog>
      {/* </div> */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={reportsData?.totalRecords / 10}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}

export default Reports;
