import React, { useEffect, useState } from "react";
import logo from "../../../assets/Logo_Round.png";
import done from "../../../assets/done.png";

import OtpInput from "react-otp-input";
import { useError } from "../../utils/error/ErrorContext";
import { validateEmail } from "../../utils/Validations";
import {
  PostData,
  PostForgotPassword,
  fetchData,
  sendEmailOTP,
} from "../../../api/apiService";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";

interface ForgotPasswordProps {
  setForgotPassword?: any;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  setForgotPassword,
}) => {
  const { showError } = useError();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cnfpassword, setCnfPassword] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [otp, setOtp] = useState("");

  const handleSubmitVerificationCode = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    console.log("Verify code logic here:", verificationCode);
    setStep(3);
  };

  const handleNewPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Send handleNewPassword logic here:", email);
    if (password === cnfpassword) {
      const Response = await PostForgotPassword(
        "admin/auth/reset-password/verify",
        {
          email_id: email,
          otp: otp,
          new_password: password,
        }
      );
      console.log("Response__", Response);
      if (Response.success) {
        setStep(4);
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else {
        showError(Response?.message);
      }
    } else {
      showError("Passwords Are not Same");
    }
  };

  const handleSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Send email logic here:", email);
    if (validateEmail(email)) {
      try {
        // const otpResponse = await sendEmailOTP(email);
        let apiPayload = {
          email_id: email,
        };
        const otpResponse = await axios.post(
          "admin/auth/reset-password",
          apiPayload
        );

        // if (!otpResponse?.ok) {
        setStep(2);
        // }
        console.log("OTP Response:", otpResponse);
      } catch (error: any) {
        console.error("Error handling Email OTP:", error);
        showError(error.response.data.message);
      }
    }
  };

  return (
    <div>
      {step === 1 && (
        <>
          {/* <section className="login-container"> */}
          {/* <h2>Forgot Password</h2> */}
          <div className="logo-container">
            <img
              src={logo}
              alt="logo"
              style={{ width: "56px", height: "50px" }}
            />
            {/* <p className="headline">A SMART MOBILITY TO WORK</p> */}
          </div>

          <div className="input-container">
            <form
              onSubmit={handleSubmitEmail}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                marginTop: "20px",
              }}
            >
              <label htmlFor="password">Forget Password:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input"
                placeholder="Enter email"
                required
              />
              <p
                style={{
                  color: "#A1A1A1",
                  lineHeight: "12.1px",
                  margin: "15px 0px",
                }}
              >
                Enter the email to verify, you will receive 4 digits code to
                your email.
              </p>
              <div className="btn-conatiner">
                <button className="login-button" type="submit">
                  Continue
                </button>
              </div>
            </form>
          </div>
          {/* </section> */}
        </>
      )}

      {step === 2 && (
        <>
          <div className="logo-container">
            <img
              src={logo}
              alt="logo"
              style={{ width: "56px", height: "50px" }}
            />
            {/* <p className="headline">A SMART MOBILITY TO WORK</p> */}
          </div>

          <div className="input-container">
            <form
              onSubmit={handleSubmitVerificationCode}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
              }}
            >
              <label htmlFor="verificationCode">Enter 4 digits code:</label>
              <p>Enter 4 digits code that you received on your email.</p>

              {/* <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="input"
                required
              /> */}
              <OtpInput
                value={otp}
                onChange={setOtp}
                inputStyle={{
                  // set height and width here
                  width: "55px", // set your desired width
                  height: "55px", // set your desired height
                  margin: "10px 0px",
                  borderRadius: "8px",
                }}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              <p style={{ textAlign: "center", color: "red" }}>00:30</p>
              <p style={{ textAlign: "center", margin: "10px 0px" }}>
                If you did not receive, re-send the code ! Re-send
              </p>
              {/* <button type="submit">Verify Code</button> */}
              <div className="btn-conatiner">
                <button className="login-button" type="submit">
                  Verify
                </button>
              </div>
            </form>
          </div>
        </>
      )}

      {step === 3 && (
        <>
          <div className="logo-container">
            <img
              src={logo}
              alt="logo"
              style={{ width: "56px", height: "50px" }}
            />
            {/* <p className="headline">A SMART MOBILITY TO WORK</p> */}
          </div>

          <div className="input-container">
            <form
              onSubmit={handleNewPassword}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                marginTop: "20px",
              }}
            >
              <label htmlFor="password">New Password:</label>
              <input
                type="password"
                id="new_password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input"
                placeholder="Enter Password"
                required
              />
              <label htmlFor="password">Confirm Password:</label>
              <input
                type="password"
                id="confirm_password"
                value={cnfpassword}
                onChange={(e) => setCnfPassword(e.target.value)}
                className="input"
                placeholder="Confirm Password"
                required
                style={{ marginBottom: "15px" }}
              />

              <div className="btn-conatiner">
                <button className="login-button" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </>
      )}

      {step === 4 && (
        <>
          <div className="logo-container">
            <img
              src={logo}
              alt="logo"
              style={{ width: "56px", height: "50px" }}
            />
            {/* <p className="headline">A SMART MOBILITY TO WORK</p> */}
          </div>
          <p style={{ color: "#fff", textAlign: "center" }}>
            Your password has been successfully reset
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            <img src={done} alt="done" />
          </div>

          <h2
            style={{
              color: "#fff",
              textAlign: "center",
              fontWeight: 500,
              margin: "10px 0px",
            }}
          >
            Successfully
          </h2>
          {/* <p>Your email has been verified. Now you can reset your password.</p> */}
          {/* Add password reset form or link to another component */}

          <div className="btn-conatiner">
            <button
              className="login-button"
              type="submit"
              // onClick={setForgotPassword(false)}
            >
              Continue
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
