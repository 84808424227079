/** @format */

import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { fetchData } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";

const ViewReport = () => {
  const param = useParams();

  const navigate = useNavigate();
  const [tripData, setTripData] = useState({
    email_id: "",
    name: "",
    pickup_location: "",
    drop_location: "",
    pickup_time: "",
    dropoff_time: "",
    status: "",
    actual_pickup_time: "",
    actual_dropoff_time: "",
    chauffeur: {
      name: "",
    },
  });
  useEffect(() => {
    const fetchDataForTrip = async () => {
      try {
        // Fetch data using the tripId
        const reportData = await fetchData(
          `admin/roster/report/trip/${param?.slug}`
        );

        setTripData(reportData.report);
      } catch (error) {
        // Handle errors
      }
    };

    fetchDataForTrip();
  }, []);

  const navigateToUserlist = () => {
    navigate("/reports");
  };

  return (
    <>
      <Header />

      <Box m="20px">
        <Box>
          <h3 className="page-title">View Report </h3>
        </Box>
        <Card className="add_card">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Rider's Name:</Form.Label>
                <Form.Control
                  name="name"
                  value={tripData?.name}
                  placeholder="Enter your name"
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Chauffeur's Name:</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="email_id"
                  value={tripData?.chauffeur?.name}
                  placeholder="Enter Email Id"
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Pickup Location:</Form.Label>
                <Form.Control
                  type="text"
                  value={tripData?.pickup_location}
                  placeholder="Enter Phone Number"
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Drop Location:</Form.Label>
                <Form.Control
                  type="text"
                  value={tripData?.drop_location}
                  name="email_id"
                  placeholder="Enter Emergency Phone Number"
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Pickup Time</Form.Label>
                <Form.Control
                  type="text"
                  name="driving_license"
                  required
                  placeholder="Enter Driving License Number"
                  value={moment(tripData?.pickup_time).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Drop Off Time</Form.Label>
                <Form.Control
                  type="text"
                  value={moment(tripData?.dropoff_time).format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                  placeholder="Enter Driving License Number"
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Actual Pickup Time</Form.Label>
                <Form.Control
                  type="text"
                  name="actual_pickup_time"
                  required
                  placeholder="Enter Actual Pickup Time"
                  value={
                    tripData?.actual_pickup_time
                      ? moment(tripData?.actual_pickup_time).format(
                          "DD/MM/YYYY hh:mm A"
                        )
                      : "Not Available"
                  }
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Actual Drop Off Time</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    tripData?.actual_dropoff_time
                      ? moment(tripData?.actual_dropoff_time).format(
                          "DD/MM/YYYY hh:mm A"
                        )
                      : "Not Available"
                  }
                  placeholder="Enter Actual Drop Off Time"
                  className="new_form_control"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Status:</Form.Label>
              <Form.Control
                type="text"
                value={tripData?.status}
                name="email_id"
                placeholder="Enter Emergency Phone Number"
                className="new_form_control"
              />
            </Form.Group>
          </Row>

          <div>
            <button
              type="button"
              className="btn "
              style={{
                width: "15%",
                backgroundColor: "lightgray",
                marginLeft: "10px",
              }}
              onClick={navigateToUserlist}
            >
              Go Back To Reports
            </button>
          </div>
        </Card>
      </Box>
    </>
  );
};

export default ViewReport;
