import { useError } from "../components/utils/error/ErrorContext";
import axios from "./axios";
export const BASE_URL = "https://api.gods-eye.in/api";

// HTTP GET Request
export const getData = async (endpoint: string) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchMapData = async (url: any) => {
  try {
    // const token = localStorage.getItem("token");

    const response = await axios.get(`${BASE_URL}/${url}`, {
      headers: {
        // cookie: `Bearer ${token}`,
        username: "admin@2024drinapi",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchData = async (url: any) => {
  console.log('first')
  try {
    const token = localStorage.getItem("token_info");

    const response = await axios.get(`${BASE_URL}/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
console.log('response111111', response)
    return response.data;
  } catch (error) {
    console.log("Failed to fetch data:", error);
    throw error
  }
};

// HTTP POST Request
export const PostData = async (endpoint: string, body: any) => {
  try {
    const token = localStorage.getItem("token_info");
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
      credentials: "include",
    });
    if (!response.ok) {
      console.log("showError", response);
      throw new Error("Failed to post data");
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const PostForgotPassword = async (endpoint: string, body: any) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    });
    // if (!response.ok) {
    //   // console.log("showError", response);
    //   throw new Error("Failed to post data");
    //   // showError("User with the provided email or mobile already exists")
    // }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const PostDataWithImage = async (
  endpoint: string,
  formData: FormData
) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "POST",
      body: formData,
      credentials: "include",
    });

    if (!response.ok) {
      console.log("showError", response);
      throw new Error("Failed to post data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

// HTTP PUT Request
export const PutData = async (endpoint: string, body: any) => {
  try {
    const token = localStorage.getItem("token_info");
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error("Failed to put data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error putting data:", error);
    throw error;
  }
};

export const postFormData = async (url: any, formDataObject: any) => {
  try {
    const token = localStorage.getItem("token_info");
    const response = await axios.post(url, formDataObject, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export const PutDataWithImage = async (
  endpoint: string,
  formData: FormData
) => {
  try {
    const token = localStorage.getItem("token_info");
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
      // credentials: "include",
    });

    if (!response.ok) {
      console.log("showError", response);
      throw new Error("Failed to post data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

// HTTP DELETE Request
export const deleteData = async (endpoint: string) => {
  try {
    const token = localStorage.getItem("token_info");
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const deleteDataForRoster = async (endpoint: string, data?: any) => {
  try {
    const token = localStorage.getItem("token_info");
    const url = `${BASE_URL}/${endpoint}`;
    const options: RequestInit = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Failed to delete data");
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};


export const sendEmailOTP = async (email: any) => {
  try {
    const response = await axios.post("admin/auth/reset-password", {
      email_id: email,
    });

    const { status, data } = response;

    if (status === 200) {
      console.log("Email OTP sent successfully");
      console.log("Response data:", data);
      return data;
    } else {
      console.error("Failed to send Email OTP");
      console.error("Error response:", response);
      throw new Error("Failed to send Email OTP");
    }
  } catch (error: any) {
    console.error("Error sending Email OTP:", error.request.response);
    const errorObj = error.request.response?.message;
    throw new Error(errorObj.message);
  }
};
