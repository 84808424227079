/** @format */

import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import "./AddUser.css";
import Header from "../../components/header/Header";
import Loader from "../../components/utils/Loader";
import axios from "../../api/axios";
import { fetchData, getData, PostData, PutData } from "../../api/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { useError } from "../../components/utils/error/ErrorContext";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BASE_URL = "https://api.gods-eye.in/api";

const EditUser = () => {
  const param = useParams();
  const { showError } = useError();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email_id: "abc2@gmail.com",
    mobile: "7974103505",
    name: "Sourabh Majumdar",
    user_type: "RIDER",
    city: "Bangalore",
    emergency_contact: "2342311929",
    home_address: " ",
    work_address: " ",
    employee_id: "ESRI-12311",
    longitude: "",
    latitude: "",
    profile_picture: null,
    organization: "GOOGLE",
    transport_date: "",
    transport_status: "",
  });

  const [locationData, setLocationData] = useState<any>();
  const [imagePreview, setImagePreview] = useState<any>();
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const data = await fetchData(`user/admin/${param?.slug}`);
        setFormData(data?.data);
        setImagePreview(data?.data?.profile_picture);
        if (data?.data?.transport_date) {
          setStartDate(new Date(data.data.transport_date));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setLoading(true);
    fetchDataAndUpdateState();
  }, []);

  const handleRemoveImage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSelectedImage(undefined); // Pass undefined instead of null
    setImagePreview(null);

    // Clear the file input value
    const fileInput = document.getElementById(
      "upload-photo"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ""; // Set the value to an empty string to clear the input
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token_info");
      let responseData;

      const formDataObject = new FormData();
      formDataObject.append("email_id", formData.email_id);
      formDataObject.append("mobile", formData.mobile);
      formDataObject.append("name", formData.name);
      formDataObject.append("user_type", formData.user_type);
      formDataObject.append("city", formData.city);
      formDataObject.append("emergency_contact", formData.emergency_contact);
      formDataObject.append("home_address", formData.home_address);
      formDataObject.append("work_address", formData.work_address);
      formDataObject.append("employee_id", formData.employee_id);
      formDataObject.append("longitude", formData.longitude);
      formDataObject.append("latitude", formData.latitude);
      formDataObject.append("organization", formData.organization);
      formDataObject.append("transport_date", formData.transport_date);
      formDataObject.append("transport_status", formData.transport_status);
      if (selectedImage) {
        formDataObject.append("image", selectedImage);
      }

      // await PostDataWithImage("user/admin/create", formDataObject);
      const response = await axios.put(
        `${BASE_URL}/user/admin/update/${param?.slug}`,
        formDataObject,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      responseData = response.data;
      // await PutData(`user/admin/update/${param?.slug}`, formDataObject);
      toast.success("User edited successfully", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    } catch (error: any) {
      if (error.isAxiosError && error.response) {
        // Axios error, handle it with status code and error message
        const statusCode = error.response.status;
        const errorMsg = error.response.data.error;
        toast.error(`HTTP Error ${statusCode}: ${errorMsg || "Unknown error"}`);
      } else {
        toast.error(`An error occurred: ${error.message || "Unknown error"}`);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleLocationChange = (id: string, selectedOption: any) => {
    if (id === "work") {
      setFormData({
        ...formData,
        work_address: selectedOption ? selectedOption.label : "",
      });
    } else if (id === "home") {
      setFormData({
        ...formData,
        home_address: selectedOption ? selectedOption.label : "",
        longitude: selectedOption?.location?.lng ?? "",
        latitude: selectedOption?.location?.lat ?? "",
      });
    }
  };

  const fetchDataAndUpdateState = async (inputValue?: any) => {
    try {
      let data;

      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setLocationData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataAndUpdateState();
  }, [formData.home_address, formData.work_address]);

  const loadOptions = async (inputValue: string) => {
    if (inputValue) {
      await fetchDataAndUpdateState(inputValue);
      const options = locationData.map((location: any) => ({
        value: location.formatted_address,
        label: location.formatted_address,
        location: location.location,
      }));
      return options;
    } else {
      return [];
    }
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      // Set selectedImage state
      setSelectedImage(file);
      setFormData({
        ...formData,
        profile_picture: file,
      });
      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const navigateToUserlist = () => {
    navigate("/users");
  };

  const defaultHomeLocation = formData.home_address
    ? {
        value: formData.home_address,
        label: formData.home_address,
        location: {
          lat: formData.latitude,
          lng: formData.longitude,
        },
      }
    : null;

  const defaultWorkLocation = formData.work_address
    ? {
        value: formData.work_address,
        label: formData.work_address,
        location: {
          lat: formData.latitude,
          lng: formData.longitude,
        },
      }
    : null;

  return (
    // <>
    //   <Header />

    //   {loading ? (
    //     <Loader />
    //   ) : (
    //     <form onSubmit={handleSubmit}>
    //       <div className="users-main">
    //         <div className="col1-add-users-container">
    //           <div style={{ width: "100%" }} className="column1-input">
    //             <span className="input-container-add-user">
    //               <p style={{ marginLeft: "25px" }}>Name</p>
    //               <input
    //                 type="text"
    //                 name="name"
    //                 required
    //                 value={formData?.name}
    //                 onChange={handleInputChange}
    //               />
    //             </span>
    //             <span className="input-container-add-user">
    //               <p style={{ marginLeft: "25px" }}>Email</p>
    //               <input
    //                 type="text"
    //                 name="email_id"
    //                 required
    //                 value={formData?.email_id}
    //                 onChange={handleInputChange}
    //               />
    //             </span>
    //             <span className="input-container-add-user">
    //               <p style={{ marginLeft: "25px" }}>Phone#</p>
    //               <input
    //                 type="text"
    //                 name="mobile"
    //                 required
    //                 value={formData?.mobile}
    //                 onChange={handleInputChange}
    //               />
    //             </span>
    //             <span className="input-container-add-user">
    //               <p style={{ marginLeft: "25px" }}>Emergency#</p>
    //               <input
    //                 type="text"
    //                 name="emergency_contact"
    //                 required
    //                 value={formData?.emergency_contact}
    //                 onChange={handleInputChange}
    //               />
    //             </span>
    //             <span className="input-container-add-user">
    //               <p style={{ marginLeft: "25px" }}>Employee ID</p>
    //               <input
    //                 type="text"
    //                 name="employee_id"
    //                 required
    //                 value={formData?.employee_id}
    //                 onChange={handleInputChange}
    //               />
    //             </span>
    //             <span className="input-container-add-user">
    //               <p style={{ marginLeft: "25px" }}>Home</p>
    //               {/* <input
    //               type="text"
    //               name="home_address"
    //               required
    //               value={formData?.home_address}
    //               onChange={handleInputChange}
    //             /> */}
    //               <AsyncSelect
    //                 cacheOptions
    //                 id="home"
    //                 defaultOptions
    //                 loadOptions={(inputValue) => loadOptions(inputValue)}
    //                 onChange={(selectedOption) =>
    //                   handleLocationChange("home", selectedOption)
    //                 }
    //                 value={defaultHomeLocation}
    //               />
    //             </span>
    //             <span className="input-container-add-user">
    //               <p style={{ marginLeft: "25px" }}>Work</p>
    //               {/* <Select
    //               name="work_address"
    //               value={locationOptions.find(
    //                 (option) => option.value === formData?.work_address
    //               )}
    //               onChange={handleLocationChange}
    //               options={locationOptions}
    //             /> */}
    //               <AsyncSelect
    //                 cacheOptions
    //                 id="work"
    //                 defaultOptions
    //                 loadOptions={(inputValue) => loadOptions(inputValue)}
    //                 onChange={(selectedOption) =>
    //                   handleLocationChange("work", selectedOption)
    //                 }
    //                 value={defaultWorkLocation}
    //               />
    //             </span>
    //           </div>
    //         </div>
    //         <div className="col2-add-users-container">
    //           <div
    //             style={{
    //               display: "flex",
    //               flexDirection: "column",
    //               // alignItems: "center",
    //               justifyContent: "center",
    //               // height: "100%",
    //             }}
    //           >
    //             <span className="input-container" style={{ width: "100%" }}>
    //               <label
    //                 htmlFor="upload-photo"
    //                 style={{
    //                   width: "200px",
    //                   height: "200px",
    //                   background: "#EFEFEF",
    //                   display: "flex",
    //                   justifyContent: "center",
    //                   cursor: "pointer",
    //                 }}
    //               >
    //                 <input
    //                   type="file"
    //                   accept="image/*"
    //                   id="upload-photo"
    //                   style={{ display: "none" }}
    //                   onChange={handleImageChange}
    //                 />
    //                 {imagePreview && (
    //                   <img
    //                     src={imagePreview}
    //                     alt="Preview"
    //                     style={{
    //                       maxWidth: "100%",
    //                       maxHeight: "100%",
    //                       cursor: "pointer",
    //                     }}
    //                   />
    //                 )}
    //               </label>

    //               <p>Upload Photo</p>
    //             </span>
    //           </div>

    //           <span
    //             style={{
    //               width: "100%",
    //               height: "30%",
    //               display: "flex",
    //               justifyContent: "space-around",
    //               flexDirection: "column",
    //             }}
    //           >
    //             <span className="input-container-add-user">
    //               <p>Latitude</p>
    //               <input
    //                 type="text"
    //                 style={{ width: "60%" }}
    //                 value={formData?.latitude}
    //                 name="latitude"
    //                 onChange={handleInputChange}
    //               />
    //             </span>
    //             <span className="input-container-add-user">
    //               <>
    //                 <p>Longitude</p>

    //                 <input
    //                   type="text"
    //                   style={{ width: "60%" }}
    //                   value={formData?.longitude}
    //                   name="longitude"
    //                   onChange={handleInputChange}
    //                 />
    //               </>
    //             </span>
    //             <span
    //               className="input-container-add-user"
    //               style={{ justifyContent: "center" }}
    //             >
    //               <>
    //                 <button className="repeat-edit-button">Update user</button>
    //               </>
    //             </span>
    //           </span>
    //         </div>
    //       </div>
    //     </form>
    //   )}
    // </>
    <Box m="20px">
      <Box>
        <h3 className="page-title">Update User</h3>
      </Box>
      <Card className="add_card">
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={formData?.name}
                  onChange={handleInputChange}
                  // className="edit_form_control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Email Address:</Form.Label>
                <Form.Control
                  type="text"
                  name="email_id"
                  required
                  value={formData?.email_id}
                  // className="edit_form_control"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number:</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  required
                  value={formData?.mobile}
                  onChange={handleInputChange}
                  // className="edit_form_control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Emergency Phone Number:</Form.Label>
                <Form.Control
                  type="text"
                  name="emergency_contact"
                  required
                  value={formData?.emergency_contact}
                  onChange={handleInputChange}
                  // className="edit_form_control"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Employee_id</Form.Label>
                <Form.Control
                  type="text"
                  name="employee_id"
                  required
                  value={formData?.employee_id}
                  onChange={handleInputChange}
                  // className="edit_form_control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Organization Name:</Form.Label>

                <Form.Select
                  name="organization"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                  value={formData.organization}
                >
                  <option>Select Organization</option>
                  {window.location.hostname === "www.gods-eye.in'" ? (
                    <>
                      <option value="DAFTAR">DAFTAR</option>
                      <option value="ESRI">ESRI</option>
                    </>
                  ) : (
                    <>
                      <option value="ESRI">ESRI</option>
                    </>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Transport Status</Form.Label>
                <Form.Select
                  name="transport_status"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                  value={formData.transport_status}
                >
                  <option>Select Status</option>
                  <option value="PENDING">PENDING</option>
                  <option value="OPTED">OPTED</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 transport_date">
                <Form.Label>Transport Date:</Form.Label>

                <DatePicker
                  className="tranport_datepicker_input"
                  selected={startDate}
                  minDate={new Date()}
                  onChange={(date: any) => {
                    setStartDate(date);
                    setFormData({
                      ...formData,
                      transport_date: date,
                    });
                  }}
                  // highlightDates={[
                  //   { "background": "#00afec", "borderRadius": "50%", "color": "#fff", "date": new Date(formData.transport_date) }
                  // ]}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Image:</Form.Label>
            <Form.Control
              type="file"
              accept="images/*"
              id="imageInput"
              onChange={handleImageChange}
            />
          </Form.Group>
          {imagePreview && (
            <div
              style={{ position: "relative" }}
              onClick={(e) => {
                handleRemoveImage(e);
              }}
            >
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "10px",
                }}
              />
              <CloseIcon
                fontSize="small"
                color="warning"
                style={{
                  position: "absolute",
                  left: "99px",
                }}
              />
            </div>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Address(Work):</Form.Label>
            <AsyncSelect
              cacheOptions
              id="work"
              defaultOptions
              loadOptions={(inputValue) => loadOptions(inputValue)}
              onChange={(selectedOption) =>
                handleLocationChange("work", selectedOption)
              }
              value={defaultWorkLocation}
              className="work_input"
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Address(Home):</Form.Label>
                <AsyncSelect
                  cacheOptions
                  id="home"
                  defaultOptions
                  loadOptions={(inputValue) => loadOptions(inputValue)}
                  onChange={(selectedOption) =>
                    handleLocationChange("home", selectedOption)
                  }
                  value={defaultHomeLocation}
                  className="home_input"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Longitude:</Form.Label>
                <Form.Control
                  type="text"
                  // className="edit_form_control"
                  name="longitude"
                  value={formData?.longitude}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Latitude:</Form.Label>
                <Form.Control
                  type="text"
                  // className="edit_form_control"
                  value={formData?.latitude}
                  name="latitude"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <div>
            <button
              style={{ width: "90px" }}
              type="submit"
              className="btn btn-success"
            >
              Update
            </button>

            <button
              type="button"
              className="btn "
              style={{
                width: "90px",
                backgroundColor: "lightgray",
                marginLeft: "10px",
              }}
              onClick={navigateToUserlist}
            >
              Cancel
            </button>
          </div>
        </Form>
      </Card>
    </Box>
  );
};

export default EditUser;
