import { createContext, useState, Dispatch, SetStateAction, useEffect, ReactNode } from 'react';

export interface AuthData {
  email: string;
  password: string;
  roles: string[];
  accessToken: string;
}

export interface AuthContextType {
  auth: AuthData;
  setAuth: Dispatch<SetStateAction<AuthData>>;
}

const AuthContext = createContext<AuthContextType>({
  auth: {
    email: '',
    password: '',
    roles: [],
    accessToken: '',
  },
  setAuth: () => {},
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children } : any) => {
  const [auth, setAuth] = useState<AuthData>({
    email: '',
    password: '',
    roles: [],
    accessToken: '',
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
