/** @format */

import React, { useState, useEffect } from "react";
import "../styledTable.css";
import { fetchData } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import Loader from "../../components/utils/Loader";
import ReactPaginate from "react-paginate";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { MdOutlinePreview } from "react-icons/md";
import moment from "moment";

function Sos({ handleCreateRide }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sosData, setSosData] = useState<any>();

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  const fetchDataAndUpdateState = async (page: number) => {
    try {
      setLoading(true);
      const pageSize = 10;
      const pageNumber = page + 1;
      const response = await fetchData(
        `sos/getAllSos?page=${pageNumber}&pageSize=${pageSize}`
      );
      setSosData(response.data.sosData);
      console.log("sosoosooooo", response.data.sosData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchDataAndUpdateState(currentPage);
  }, [currentPage]);

  return (
    <>
      <Header />
      <div className="schedule-main">
        <div className="button-div">
          <div>
            <h6 className="reports">Service Request Queue</h6>
          </div>
          <input type="text" />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <table
            className="schedule-table table-no-border"
            style={{ borderSpacing: "0 1rem" }}
          >
            <thead>
              <tr className="table-head">
                <th>Sr. No</th>
                <th>User's Name</th>
                <th>User's Type</th>
                <th>Ticket Raised Time</th>
                <th>Ticket Staus</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sosData &&
                sosData.map((sosItem: any, index: number) => {
                  const serialNumber = index + 1 + currentPage * 10;
                  return (
                    <>
                      <tr className="table-body" key={sosItem.Id}>
                        <td style={{ paddingBottom: "10px" }}> {serialNumber}</td>

                        <td>{sosItem.user.name}</td>
                        <td>{sosItem.user.user_type}</td>
                        <td>
                          {moment(sosItem.created_at).format(
                            "DD/MM/YYYY hh:mm:ss"
                          )}
                        </td>
                        <td
                          style={{
                            color:
                              sosItem.ticket_status === "COMPLETED"
                                ? "green"
                                : "red",
                          }}
                        >
                          {sosItem.ticket_status}
                        </td>
                        {window.location.hostname === "www.gods-eye.in" ||
                        window.location.hostname === "localhost" ? (
                          <td>
                            <Tooltip title="View/edit sos">
                              <IconButton>
                                <MdOutlinePreview
                                  style={{
                                    height: "35px",
                                    width: "30px",

                                    color: "grey",
                                  }}
                                  onClick={() =>
                                    navigate(`/editSos/${sosItem?.Id}`)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </td>
                        ) : (
                          <td>
                            <Tooltip title="View sos">
                              <IconButton>
                                <MdOutlinePreview
                                  style={{
                                    height: "35px",
                                    width: "30px",

                                    color: "grey",
                                  }}
                                  onClick={() =>
                                    navigate(`/viewSos/${sosItem?.Id}`)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={sosData?.totalRecords / 10}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}

export default Sos;
