import React, { useEffect, useState } from "react";
import StepTwo from "../schedule/createRide/StepTwo";
import StepFour from "../schedule/createRide/StepFour";
import Header from "../../components/header/Header";
import { MdCancel } from "react-icons/md";
import UserRides from "./UserRides";
import { PostData } from "../../api/apiService";
// import "./createRide/create_ride.css";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ScheduleUserRides() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [shownDetails, setShownDetails] = useState([{}]);

  const [userDetails, setUserDetails] = useState({
    mobile: "",
    email: "",
    name: "",
    organization: "",
  });

  const [chauffeurDetails, setChauffeurDetails] = useState({
    mobile: "",
    email: "",
    vehicleNumber: "",
    chauffeurName: "",
  });
  const [stepTwoData, setStepTwoData] = useState(userDetails);
  const [stepOneUserDetails, setStepOneUserDetails] = useState<any[]>([]);
  const [newUsers, setNewUsers] = useState(stepOneUserDetails[0]?.users);
  const [userData, setUserData] = useState<any>({
    users: [
      {
        rider_user_id: 0,
        pickup_location: "",
        dropoff_location: "",
        pickup_time: "",
        dropoff_time: "",
      },
    ],
    chauffeur_user_id: 0,
    dates_list: ["2023-01-01", "2023-01-02"],
  });
  const [users, setUsers] = useState<any[]>([]);

  const [createRide, setCreateRide] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const handleCreateRide = () => {
    setCreateRide(!createRide);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    if (currentStep - 1 != 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    // event.preventDefault();

    const payload = {
      users: userData?.users.map((user: any, index: any) => ({
        rider_user_id: user.rider_user_id,
        pickup_location: user.pickup_location || "",
        dropoff_location: user.dropoff_location || "",
        pickup_time: user?.pickup_time,
        dropoff_time: user?.dropoff_time,
      })),
      chauffeur_user_id: userData?.chauffeur_user_id,
      dates_list: stepOneUserDetails[0]?.dates_list,
    };

    try {
      await PostData("admin/roster/create", payload);
      setModalVisible(true);
      toast.success("Ride has been created successfully!");
      setTimeout(() => {
        navigate("/schedule-rides");
      }, 1000);
    } catch (error) {
      // Handle error
      console.error("Error creating ride:", error);
      // Show error toast
      toast.error("Failed to create ride. Please try again later.");
    }
  };



  useEffect(() => {
    setNewUsers(stepOneUserDetails[0]?.users);
  }, [stepOneUserDetails]);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <UserRides
            userData={userData}
            setUserData={setUserData}
            onNext={handleNext}
            handlePrev={handlePrev}
            // currentStep={currentStep}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            chauffeurDetails={chauffeurDetails}
            setChauffeurDetails={setChauffeurDetails}
            setStepOneUserDetails={setStepOneUserDetails}
            setShownDetails={setShownDetails}
            shownDetails={shownDetails}
            setNewUsers={setNewUsers}
            stepOneUserDetails={stepOneUserDetails}
          />
        );
      case 2:
        return (
          <StepTwo
            userData={userData}
            setUserData={setUserData}
            users={users}
            stepOneUserDetails={stepOneUserDetails}
            setUsers={setUsers}
            stepTwoData={stepTwoData}
            setStepTwoData={setStepTwoData}
            onNext={handleNext}
            handlePrev={handlePrev}
            newUsers={newUsers}
            setNewUsers={setNewUsers}
          />
        );
      case 3:
        return (
          <StepFour
            userData={userData}
            onSave={handleSubmit}
            handlePrev={handlePrev}
            newUsers={newUsers}
            setNewUsers={setNewUsers}
          />
        );
      default:
        return null;
    }
  };


  return (
    <div className="title">
      <Header />
      {renderStep()}
      {isModalVisible && (
        <div className="modal">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <MdCancel
              onClick={() => setModalVisible(false)}
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
            />
          </div>
          <p>Ride has been created!</p>
        </div>
      )}
    </div>
  );
}

export default ScheduleUserRides;
