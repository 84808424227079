/** @format */

import React, { useContext, useEffect, useState } from "react";
import { fetchData } from "../../api/apiService";
import moment from "moment";
import "./dashboard.css";
import AuthContext from "../../context/AuthProvider";
import Loader from "../../components/utils/Loader";
import { CustomBarChart } from "../../components/utils/BarChart";
import EsriLogo from "../../assets/Rectangle 398 (1).png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = ({ setIsLoginPage }: any) => {
  const [dashboardData, setDashboardData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const dashboardDate = moment();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    setIsLoginPage(false);
    const fetchDataAndUpdateState = async () => {
      try {
        const data = await fetchData("admin/home/");
        setDashboardData(data);
        console.log("dat===>",dashboardData)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchDataAndUpdateState();

    setTimeout(() => {
      localStorage.removeItem("loggedIn");
    }, 100000);
  }, []);

  const months = moment.monthsShort();

  const monthCounts = Array.from({ length: 12 }, () => 0);

  if (dashboardData && dashboardData.no_show_count_stats) {
    dashboardData.no_show_count_stats.forEach(
      ({ month, no_show_count }: { month: number; no_show_count: number }) => {
        monthCounts[month - 1] = no_show_count;
      }
    );
  }

  const barChartData1 = {
    labels: months, 
    datasets: [
      {
        label: "No Show Count",
        data: monthCounts,
        backgroundColor: ["#7FC38C", "#F37C24", "#DED3F1", "#B0D0EF", "#7B66FF"],
      },
    ],
  };
  const barChartData = {
    labels: ["User", "Chauffeur", "Vehicle", "City"],
    datasets: [
      {
        label: "Count",
        data: [
          dashboardData?.total_rider_count,
          dashboardData?.total_chauffeur_count,
          dashboardData?.total_vehicle_count,
          dashboardData?.rider_cities_count,
        ],
        backgroundColor: ["#7FC38C", "#CFE9D6", "#C0C0C0", "#7B66FF"],
      },
    ],
  };
  // Check if the hostname is esri.gods-ete.in
  const isEsriHostname = window.location.hostname === "esri.gods-eye.in";

  return (
    <>
      {loading && <Loader />}
      {dashboardData && (
        <div className="dashboard-main">
          <div className="navbar-right">
            {isEsriHostname && (
              <div className="esri-logo-container">
                <img src={EsriLogo} alt="Esri Logo" className="esri-logo" />
              </div>
            )}
          </div>
          <div className="mid">
            <div className="mid-container">
              <span className="total-conatiner" style={{ cursor: "pointer" }}>
                <p className="total-heading">Total User</p>
                <div className="result-box">
                  <p className="total-number">
                    {dashboardData?.total_rider_count}
                  </p>
                </div>
              </span>
              <span className="total-conatiner" style={{ cursor: "pointer" }}>
                <p className="total-heading">Total Chauffeur</p>
                <div className="result-box">
                  <p className="total-number">
                    {dashboardData?.total_chauffeur_count}
                  </p>
                </div>
              </span>
            </div>
            <div className="mid-container">
              <span
                className="total-conatiner"
                style={{ justifyContent: "space-evenly", cursor: "pointer" }}
              >
                <div>
                  <p className="total-heading">Total Vehicle</p>
                  <h3 className="dashboard-date">Feb 1, 2023 - Feb 29, 2024</h3>
                </div>
                <div>
                  <p className="total-heading">City</p>
                  <h3 className="dashboard-date">Feb 1, 2023 - Feb 29, 2024</h3>
                </div>
              </span>
              <span
                className="total-conatiner"
                style={{ justifyContent: "space-evenly", cursor: "pointer" }}
              >
                <div className="result-box">
                  <p className="total-number">
                    {dashboardData?.total_vehicle_count}
                  </p>
                </div>
                <div className="result-box">
                  <p className="total-number">
                    {dashboardData?.rider_cities_count}
                  </p>
                </div>
              </span>
            </div>
          </div>

          <div className="right">
            {(window.location.hostname === 'www.gods-eye.in' 
            || window.location.hostname === 'localhost'
            )  
             ? (
              <>
                <div className="schedule-container">
                  <span style={{ cursor: "pointer" }}>Upcoming Schedule</span>
                  <div className="date-conatiner">
                    <span>
                      <p>{moment(dashboardDate).format("ddd")} </p>{" "}
                      <p>{moment(dashboardDate).format("D")}</p>{" "}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
           <div className="no_show_chart">
            <p className="heading_no_show" >Statistics for No Show</p>
           <Bar data={barChartData1} />
           </div>
              </>
            )}

            <CustomBarChart data={barChartData} />
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
