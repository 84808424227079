import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import "./AddUser.css";
import Header from "../../components/header/Header";
import Loader from "../../components/utils/Loader";
import axios from "../../api/axios";
import { fetchData, getData, PostData, PutData } from "../../api/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { useError } from "../../components/utils/error/ErrorContext";
import AsyncSelect from "react-select/async";
import { Box } from "@mui/material";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
const BASE_URL = "https://api.gods-eye.in/api";

const EditChauffeur = () => {
  const param = useParams();
  const { showError } = useError();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email_id: "abc4@gmail.com",
    mobile: "7974103512",
    name: "Sourabh Majumdar",
    profile_picture: null,
    user_type: "CHAUFFEUR", //RIDER or CHAUFFEUR
    city: "Bangalore",
    emergency_contact: "2342311929",
    home_address:
      "Gunjur-Doddakannelli Rd, Chikkabellandur, Bengaluru, Karnataka 560035",
    work_address:
      "WMGV+P2V, Devarabisanahalli, Bellandur, Bengaluru, Karnataka 560103",
    user_details: {
      driving_license: "ABC221100",
      adhaar: "AKAJLJFLA",
    },
    vehicle: {
      type: "Sedan",
      license_plate: "KA-01-1231",
    },
    license_plate: "KA-01-1231",
    type: "",
    driving_license: "",
    adhaar: "",
    employee_id: "DAFTAR-12311",
    latitude: "12.9716",
    longitude: "77.5946",
  });

  const [locationData, setLocationData] = useState<any>();
  const [imagePreview, setImagePreview] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const data = await fetchData(`user/admin/${param?.slug}`);
        setFormData(data?.data);
        if (data?.data?.profile_picture) {
          setImagePreview(data?.data?.profile_picture);
        } else {
          setImagePreview(null); // Ensure imagePreview is null if no image
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setLoading(true);
    fetchDataAndUpdateState();
  }, []);
  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token_info");
      let responseData;
      const formDataObject = new FormData();
      formDataObject.append("email_id", formData.email_id);
      formDataObject.append("mobile", formData.mobile);
      formDataObject.append("name", formData.name);
      formDataObject.append("user_type", formData.user_type);
      formDataObject.append("emergency_contact", formData.emergency_contact);
      formDataObject.append("home_address", formData.home_address);
      formDataObject.append("work_address", formData.work_address);
      formDataObject.append("organization", "DAFTAR");
      formDataObject.append("employee_id", formData.employee_id);
      formDataObject.append("city", formData.city);
      formDataObject.append("longitude", formData.longitude);
      formDataObject.append("latitude", formData.latitude);
      formDataObject.append(
        "user_details[adhaar]",
        formData.user_details.adhaar
      );
      formDataObject.append("vehicle[type]", formData.vehicle.type);
      formDataObject.append(
        "user_details[driving_license]",
        formData.user_details.driving_license
      );
      formDataObject.append(
        "vehicle[license_plate]",
        formData.vehicle.license_plate
      );
      console.log("Selected Image:", selectedImage); 
      if (selectedImage) {
        formDataObject.append("image", selectedImage);
      }

      const response = await axios.put(
        `${BASE_URL}/user/admin/update/${param?.slug}`,
        formDataObject,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      responseData = response.data;
      // await PutData(`user/admin/update/${param?.slug}`, formDataObject);
      toast.success("Chauffeur edited successfully", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        navigate("/chauffeur");
      }, 1000);
    } catch (error: any) {
      toast.error("error updating");
      if (error.isAxiosError && error.response) {
        // Axios error, handle it with status code and error message
        const statusCode = error.response.status;
        const errorMsg = error.response.data.error;
        showError(`HTTP Error ${statusCode}: ${errorMsg || "Unknown error"}`);
      } else {
        showError(`An error occurred: ${error.message || "Unknown error"}`);
      }
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    nestedKey?: string
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => {
      if (nestedKey && prevFormData.hasOwnProperty(nestedKey)) {
        return {
          ...prevFormData,
          [nestedKey]: {
            ...prevFormData[nestedKey],
            [name]: value,
          },
        };
      } else {
        return {
          ...prevFormData,
          [name]: value,
        };
      }
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      vehicle: {
        ...prevFormData.vehicle,
        [name]: value,
      },
    }));
  };

  const handleRemoveImage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSelectedImage(undefined); 
    setImagePreview(null);
    console.log("After removing image, imagePreview:", imagePreview);

    // Clear the file input value
    const fileInput = document.getElementById("imageInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ""; // Set the value to an empty string to clear the input
    }
  };
  const handleLocationChange = (id: string, selectedOption: any) => {
    if (id === "work") {
      setFormData({
        ...formData,
        work_address: selectedOption ? selectedOption.label : "",
      });
    } else if (id === "home") {
      setFormData({
        ...formData,
        home_address: selectedOption ? selectedOption.label : "",
        // longitude: selectedOption?.location?.lng ?? "",
        // latitude: selectedOption?.location?.lat ?? "",
      });
    }
  };

  const fetchDataAndUpdateState = async (inputValue?: any) => {
    try {
      let data;

      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setLocationData(data?.data);
      // setFormData({
      //   ...formData,
      //   image: data ? data?.profile_picture : "",
      // });
      //   setImagePreview(data?.profile_picture);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataAndUpdateState();
  }, [formData.home_address, formData.work_address]);

  const loadOptions = async (inputValue: string) => {
    if (inputValue) {
      await fetchDataAndUpdateState(inputValue);
      const options = locationData.map((location: any) => ({
        value: location.formatted_address,
        label: location.formatted_address,
        location: location.location,
      }));
      return options;
    } else {
      return [];
    }
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      // Set selectedImage state
      setSelectedImage(file);
      setFormData({
        ...formData,
        profile_picture: file,
      });
      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const defaultHomeLocation = formData.home_address
    ? {
        value: formData.home_address,
        label: formData.home_address,
        // location: {
        //   lat: formData?.latitude ?? null,
        //   lng: formData.longitude ?? null,
        // },
      }
    : null;

  const defaultWorkLocation = formData.work_address
    ? {
        value: formData.work_address,
        label: formData.work_address,
        // location: {
        //   lat: formData.latitude,
        //   lng: formData.longitude,
        // },
      }
    : null;

  return (
    <>
      <Header />

      <Box m="20px">
        <Box>
          <h3 className="page-title">Update Chauffeur</h3>
        </Box>
        <Card className="add_card">
          <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="name"
                    placeholder="Enter your name"
                    onChange={handleInputChange}
                    value={formData?.name}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="email_id"
                    placeholder="Enter Email Id"
                    onChange={handleInputChange}
                    value={formData?.email_id}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="mobile"
                    placeholder="Enter Phone Number"
                    onChange={handleInputChange}
                    value={formData?.mobile}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Emergency Phone Number:</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="emergency_contact"
                    placeholder="Enter Emergency Phone Number"
                    onChange={handleInputChange}
                    value={formData?.emergency_contact}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Aadhaar Card Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="adhaar"
                    required
                    placeholder="Enter Aadhar Card Number"
                    onChange={(e) => handleInputChange(e, "user_details")}
                    value={formData.user_details.adhaar} // Update this line
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Employee_id</Form.Label>
                  <Form.Control
                    type="text"
                    name="employee_id"
                    required
                    value={formData?.employee_id}
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Image:</Form.Label>
              <Form.Control
                type="file"
                accept="images/*"
                id="imageInput"
                onChange={handleImageChange}
              />
            </Form.Group>
            {imagePreview && (
              <div
                style={{ position: "relative" }}
                onClick={(e) => {
                  handleRemoveImage(e);
                }}
              >
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    height: "100px",
                    width: "100px",
                    margin: "10px",
                  }}
                />
                <CloseIcon
                  fontSize="small"
                  color="warning"
                  style={{
                    position: "absolute",
                    left: "99px",
                  }}
                />
              </div>
            )}
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Address(Work):</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    id="work"
                    placeholder="Select Work Address"
                    value={defaultWorkLocation}
                    loadOptions={(inputValue) => loadOptions(inputValue)}
                    onChange={(selectedOption) =>
                      handleLocationChange("work", selectedOption)
                    }
                    className="address_input"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Address(Home):</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    id="home"
                    placeholder="Select Home Address"
                    value={defaultHomeLocation}
                    loadOptions={(inputValue) => loadOptions(inputValue)}
                    onChange={(selectedOption) =>
                      handleLocationChange("home", selectedOption)
                    }
                    className="address_input"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Driving License Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="driving_license"
                    required
                    value={formData?.user_details?.driving_license} // Update this line
                    placeholder="Enter Driving License Number"
                    onChange={(e) => handleInputChange(e, "user_details")}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Vehicle Registration</Form.Label>
                  <Form.Control
                    type="text"
                    name="license_plate"
                    required
                    placeholder="Enter Driving License Number"
                    onChange={(e) => handleInputChange(e, "vehicle")}
                    value={formData?.vehicle.license_plate} // Update this line
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Choose Vehicle Type</Form.Label>

                  <Form.Select
                    aria-label="Default select example"
                    name="type"
                    onChange={(e) => handleInputChange(e, "vehicle")}
                    value={formData?.vehicle.type} // Update this line
                  >
                    <option>Select Vehicle Type</option>
                    <option value="Sedan">Sedan</option>
                    <option value="MUV">MUV</option>
                    <option value="Hatchback">Hatchback</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <div>
              <button
                style={{ width: "90px" }}
                type="submit"
                className="btn btn-success"
              >
                Update
              </button>
              <button
                type="button"
                className="btn "
                style={{
                  width: "90px",
                  backgroundColor: "lightgray",
                  marginLeft: "10px",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Card>
      </Box>
    </>
  );
};

export default EditChauffeur;
