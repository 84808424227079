import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { deleteDataForRoster, fetchData } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const ViewSos = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [sosData, setSosData] = useState({
    ticket_status: "",
    created_at: "",
    description: "",
    user: {
      name: "",
      email_id: "",
      user_type: "",
      mobile:""
    },
  });

  const fetchDataForSosId = async () => {
    try {
      const sosData = await fetchData(`sos/getSosById/${param?.slug}`);
      setSosData(sosData.sos);
    } catch (error) {
      console.error("Error fetching SOS data:", error);
    }
  };

  useEffect(() => {
    fetchDataForSosId();
  }, []);

  const handleUpdateSos = async () => {
    try {
      const payload = {
        description: description,
        ticket_status: "COMPLETED",
      };
      await deleteDataForRoster(`sos/update/${param?.slug}`, payload);
      fetchDataForSosId();
      toast.success(`SOS updated successfully`, {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/sos");
    } catch (error) {
      console.error("Error updating SOS:", error);
      toast.error("Error updating SOS");
    }
  };

  const navigateToUserlist = () => {
    navigate("/sos");
  };

  return (
    <>
      <Header />
      <Box m="20px">
        <Box>
          <h3 className="page-title">View SOS</h3>
        </Box>
        <Card className="add_card">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>User's Name:</Form.Label>
                <Form.Control
                  name="name"
                  value={sosData?.user?.name}
                  placeholder="Enter your name"
                  className="new_form_control"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>User's Email:</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="email_id"
                  value={sosData?.user?.email_id}
                  placeholder="Enter Email Id"
                  className="new_form_control"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>User's Type</Form.Label>
                <Form.Control
                  type="text"
                  name="user_type"
                  required
                  value={sosData?.user?.user_type}
                  className="new_form_control"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Ticket Creation Time</Form.Label>
                <Form.Control
                  type="text"
                  value={moment(sosData?.created_at).format("DD/MM/YYYY  hh:mm:ss")}
                  className="new_form_control"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
           <Col>
           <Form.Group className="mb-3">
              <Form.Label>Ticket Status:</Form.Label>
              <Form.Control
                  type="text"
                  name="ticket_status"
                  required
                  value={sosData?.ticket_status}
                  className="new_form_control"
                  disabled
                />
            </Form.Group></Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>User's Contact Number</Form.Label>
                <Form.Control
                  type="text"
                  name="user_type"
                  required
                  value={sosData?.user?.mobile}
                  className="new_form_control"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                   as="textarea"
                   rows={3}
                   name="description"
                  required
                  value={sosData?.description}
                  className="new_form_control"
                  disabled
                />
            </Form.Group>
          </Row>

          <div>
           
            <button
              type="button"
              className="btn "
              style={{
                width: "15%",
                backgroundColor: "lightgray",
                marginLeft: "10px",
              }}
              onClick={navigateToUserlist}
            >
              Go Back To Sos
            </button>
          </div>
        </Card>
      </Box>
    </>
  );
};

export default ViewSos;
