import React, { useEffect, useState } from "react";
import "./create_ride.css";
import { fetchData } from "../../../api/apiService";

interface StepFourProps {
  onSave: () => void;
  handlePrev: () => void;
  userData: any;
  newUsers: any;
  setNewUsers: any;
}

const StepFour: React.FC<StepFourProps> = ({
  onSave,
  handlePrev,
  userData,
  newUsers,
  setNewUsers,
}) => {


  const [riderInformation, setRiderInformation] = useState<any[]>([]);


  return (
    <div className="roster-main" style={{ overflowY: "unset" }}>
      <h4>Preview Roster</h4>
      <div className="review-container" style={{ overflowY: "scroll" }}>
        <table className="review-roster table-no-border">
          <thead>
            <tr className="review-roster-table-head">
              <th>Rider's Name</th>
              <th>Pickup Address</th>
              <th>Drop Address</th>
              <th>Chauffeur's Name</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(userData?.users) &&
              userData?.users.map((rider: any, index: number) => {
                return (
                  <>
                    <tr
                      className="review-roster-table-body"
                      key={index}
                      style={{ margin: "10px 0" }}
                    >
                      <td>{rider?.name}</td>
                      <td>{userData?.users[index]?.pickup_location}</td>
                      <td>{userData?.users[index]?.dropoff_location}</td>
                      <td>{userData?.chauffeur?.name}</td>
                    </tr>
                  </>
                );
              })}
          </tbody>
       
        </table>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop:"20px"
          }}
        >
          <button
            onClick={onSave}
            style={{ width: "15%", marginLeft: "10px" }}
            className="btn btn-success"
          >
            Save Ride
          </button>
          <button
            onClick={handlePrev}
            style={{
              width: "10%",
              backgroundColor: "lightgray",
              marginLeft: "10px",
            }}
            className="btn "
          >
            Previous
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
