import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "City wise Stats",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export function CustomBarChart({ data }: any) {
  return (
    <div
      style={{
          width: "95%",
        // height: "100%",
      }}
    >
      <Bar
        options={options}
        data={data}
        style={{
          height:"900px !important",
          width: "100%",
          backgroundColor: "#EFEFEF",
          borderRadius: 15,
          padding:10,
          margin: "5px 10px"
        }}
      />
    </div>
  );
}
