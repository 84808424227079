/** @format */

import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  FormEvent,
} from "react";
import AuthContext, { AuthProvider } from "../../../context/AuthProvider";
import earthImage from "../../../assets/earth.svg";
import axios from "../../../api/axios";
import { getCookie, setCookie } from "../../utils/Utils";
import { useNavigate, Navigate } from "react-router-dom";
import logo from "../../../assets/Logo_Round.png";
import check from "../../../assets/check.png";
import "./login.css";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import ErrorMessage from "../../utils/ErrorMessage";
import { useError } from "../../utils/error/ErrorContext";
import { error } from "console";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EsriLogo from "../../../assets/esr-login.jpeg";

const LOGIN_URL = "admin/auth/sign-in";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const userRef = useRef<HTMLInputElement>(null);
  const errRef = useRef<HTMLParagraphElement>(null);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const { showError } = useError();

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const responseData = response?.data;
      const accessToken = response?.data?.token_info?.access_token;
      const roles = response?.data?.role;
      setAuth({ email, password, roles: [roles], accessToken: accessToken });

      setEmail(response?.data?.email_id);
      setPassword("");

      setCookie(
        "token_info",
        accessToken,
        response?.data?.token_info?.expires_in
      );
      localStorage.setItem("token_info", accessToken);
      localStorage.setItem("loggedIn", "true");
      setSuccess(true);
    } catch (err: any) {
      if (!err?.response) {
        toast.error("No Server Response");
      } else if (err.response?.data?.code) {
        toast.error(err.response?.data?.error);
      } else {
        toast.error("Login Failed");
      }
      if (errRef.current) {
        errRef.current.focus();
      }
    }
  };
  useEffect(() => {
    if (success) {
      toast.success("Admin login successful", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      navigate("/");
    }
  }, [success]);

  return (
    <>
      {window.location.hostname === "www.gods-eye.in" ||
      window.location.hostname === "localhost" ? (
        <>
          <> {errMsg && <ErrorMessage message={errMsg} />} </>
          {success ? (
            <> </>
          ) : (
            <AuthProvider>
              <section className="login-container">
                <div className="left-image-container">
                  <img
                    src={earthImage}
                    style={{ height: "100%" }}
                    alt="earthImage "
                  />
                </div>
                <div className="login-center-container">
                  {forgotPassword ? (
                    <>
                      <ForgotPassword setForgotPassword={setForgotPassword} />
                    </>
                  ) : (
                    <>
                      <div className="logo-container">
                        <img
                          src={logo}
                          alt="logo"
                          style={{ width: "56px", height: "50px" }}
                        />
                      </div>

                      <div className="input-container">
                        <form
                          onSubmit={handleSubmit}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "70%",
                          }}
                        >
                          <label htmlFor="username">Username:</label>
                          <input
                            type="text"
                            id="username"
                            className="input"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                          />

                          <label htmlFor="password">Password:</label>
                          <input
                            type="password"
                            id="password"
                            className="input"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                          />

                          <div
                            className="remember"
                            onChange={() => setRememberMe(!rememberMe)}
                          >
                            {/* <div> */}
                            <input
                              type="checkbox"
                              id="Remember-me"
                              defaultChecked={true}
                            />
                            {rememberMe ? (
                              // ✔️
                              <img
                                src={check}
                                alt="check"
                                style={{
                                  zIndex: 100,
                                  marginLeft: "1px",
                                  width: "19px",
                                  marginBottom: 6, // Breaking the UI need to fix for now
                                  cursor: "pointer",
                                }}
                                onClick={() => setRememberMe(!rememberMe)}
                              />
                            ) : (
                              <img
                                src={check}
                                alt="check"
                                style={{
                                  display: "none",
                                }}
                              />
                            )}
                            {/* </div> */}
                            <label
                              htmlFor="Remember-me"
                              style={{
                                margin: rememberMe ? "20px 30px" : "20px 50px",
                                cursor: "pointer",
                              }}
                            >
                              Remember me
                            </label>
                          </div>
                          <div className="btn-conatiner">
                            <button className="login-button">Login</button>
                          </div>
                          <a
                            onClick={() => setForgotPassword(!forgotPassword)}
                            style={{
                              color: "#fff",
                              marginTop: "20px",
                              cursor: "pointer",
                            }}
                          >
                            Forgot Password?
                          </a>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </AuthProvider>
          )}
        </>
      ) : (
        <>
          <> {errMsg && <ErrorMessage message={errMsg} />} </>
          {success ? (
            <> </>
          ) : (
            <AuthProvider>
              <section className="login-container-esrilogo">
                {/* <div className="left-image-container-esrilogo">
                  <img
                    src={EsriLogo}
                    style={{ height: "100%" }}
                    alt="esrilogo"
                  />
                </div> */}
                <div className="login-center-container-esrilogo">
                  {forgotPassword ? (
                    <>
                      <ForgotPassword setForgotPassword={setForgotPassword} />
                    </>
                  ) : (
                    <>
                      <div className="logo-container">
                        <img
                          src={logo}
                          alt="logo"
                          style={{ width: "56px", height: "50px" }}
                        />
                      </div>

                      <div className="input-container">
                        <form
                          onSubmit={handleSubmit}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "70%",
                          }}
                        >
                          <label htmlFor="username">Username:</label>
                          <input
                            type="text"
                            id="username"
                            className="input"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                          />

                          <label htmlFor="password">Password:</label>
                          <input
                            type="password"
                            id="password"
                            className="input"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                          />

                          <div
                            className="remember"
                            onChange={() => setRememberMe(!rememberMe)}
                          >
                            {/* <div> */}
                            <input
                              type="checkbox"
                              id="Remember-me"
                              defaultChecked={true}
                            />
                            {rememberMe ? (
                              // ✔️
                              <img
                                src={check}
                                alt="check"
                                style={{
                                  zIndex: 100,
                                  marginLeft: "1px",
                                  width: "19px",
                                  marginBottom: 6, // Breaking the UI need to fix for now
                                  cursor: "pointer",
                                }}
                                onClick={() => setRememberMe(!rememberMe)}
                              />
                            ) : (
                              <img
                                src={check}
                                alt="check"
                                style={{
                                  display: "none",
                                }}
                              />
                            )}
                            {/* </div> */}
                            <label
                              htmlFor="Remember-me"
                              style={{
                                margin: rememberMe ? "20px 30px" : "20px 50px",
                                cursor: "pointer",
                              }}
                            >
                              Remember me
                            </label>
                          </div>
                          <div className="btn-conatiner">
                            <button className="login-button">Login</button>
                          </div>
                          <a
                            onClick={() => setForgotPassword(!forgotPassword)}
                            style={{
                              color: "#fff",
                              marginTop: "20px",
                              cursor: "pointer",
                            }}
                          >
                            Forgot Password?
                          </a>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </AuthProvider>
          )}
        </>
      )}
    </>
  );
};

export default Login;
