import React, { useState, useEffect } from "react";
import error from "../../../assets/Error.png";
import { MdCancel } from "react-icons/md";
interface ErrorNotificationProps {
  message: string;
  onClose: () => void;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  message,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 1);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleAnimationEnd = () => {
    onClose();
  };

  useEffect(() => {
    const body = document.body;

    // Disable pointer events for the entire body when the error is visible
    if (isVisible) {
      body.style.pointerEvents = "none";
    } else {
      body.style.pointerEvents = "auto";
    }

    return () => {
      // Enable pointer events when the component is unmounted
      body.style.pointerEvents = "auto";
    };
  }, [isVisible]);

  return (
    <div
      style={{
        position: "fixed",
        top: isVisible ? "-50%" : "10%",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#419043",
        color: "white",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 355,
        height: 263,
        borderRadius: "18px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        transition: "top 0.5s ease-in-out",
      }}
      onAnimationEnd={handleAnimationEnd}
    >
      <button
        onClick={handleAnimationEnd}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "white",
          fontSize: "25px",
        }}
      >
        <MdCancel />
      </button>
      <div>
        <img
          src={error}
          alt="Icon"
          style={{ width: "125px", height: "125px", marginBottom: "10px" }}
        />
        <h2>Error</h2>
        <p style={{ fontSize: "16px", margin: 0 }}>{message}</p>
      </div>
    </div>
  );
};

export default ErrorNotification;
