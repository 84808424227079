/** @format */

import React, { useState, useEffect } from "react";
import "../styledTable.css";
import { fetchData, deleteData } from "../../api/apiService";
import Loader from "../../components/utils/Loader";
import { useNavigate } from "react-router-dom";
import { Dropdown, Input, InputGroup } from "rsuite";
import ReactPaginate from "react-paginate";
import "./AddUser.css";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { FaUserEdit } from "react-icons/fa";
import { MdAddLocationAlt } from "react-icons/md";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";

function ChauffeurList({ handleAddUser }: any) {
  const navigate = useNavigate();
  const [chauffeurListData, setChauffeurListData] = useState<any>();
  const [searchInput, setSearchInput] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [searchBy, setSearchBy] = useState("mobile");
  const [currentPage, setCurrentPage] = useState(0);

  const styles = {
    width: 190,
    height: 35,
    // marginBottom: 10,
  };

  // const fetchDataAndUpdateState = async () => {
  //   try {
  //     let data;
  //     if (searchInput) {
  //       data = await fetchData(
  //         `user/admin/getAll?user_type=CHAUFFEUR&${searchBy}=${searchInput}`
  //       );
  //     } else {
  //       data = await fetchData("user/admin/getAll?user_type=CHAUFFEUR");
  //     }
  //     setChauffeurListData(data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  const fetchDataAndUpdateState = async (page: number) => {
    try {
      setLoading(true);
      let data;
      const pageSize = 10; 
      const pageNumber = page + 1; 

      if (searchInput) {
        data = await fetchData(
          `user/admin/getAll?user_type=CHAUFFEUR&page=${pageNumber}&pageSize=${pageSize}&${searchBy}=${searchInput}`
        );
      } else {
        data = await fetchData(
          `user/admin/getAll?user_type=CHAUFFEUR&page=${pageNumber}&pageSize=${pageSize}`
        );
      }
      setChauffeurListData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchDataAndUpdateState(currentPage);
  }, [searchInput, , currentPage, searchBy]);

  const DeleteUserHandler = async (id: number, username: string) => {
    try {
      await deleteData(`user/admin/delete/${id}`);
      fetchDataAndUpdateState(currentPage);
      toast.success(`${username} deleted successfully`, {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user");
    }
  };

  const handleDropdownSelect = (value: any, event: any) => {
    setSearchBy(value);
  };

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  return (
    <>
      <div className="schedule-main">
        <div className="button-div">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
            }}
          >
            <Dropdown
              title={searchBy}
              onSelect={handleDropdownSelect}
              trigger="click"
              style={{
                marginRight: "10px",
                height: "100%",
                borderRadius: "10px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              <Dropdown.Item eventKey="mobile">Mobile</Dropdown.Item>
              <Dropdown.Item eventKey="email_id">Email</Dropdown.Item>
            </Dropdown>
            <InputGroup style={styles}>
              <Input
                onChange={(val) => setSearchInput(val)}
                placeholder="Search..."
              />
            </InputGroup>
          </div>
          {(window.location.hostname === 'www.gods-eye.in' || window.location.hostname === 'localhost')  ? (
            <button
              className="add_button"
              onClick={() => navigate("/chauffeur/add")}
            >
              Add Chauffeur
            </button>
          ) : (
            <></>
          )}
        </div>

        <table className="schedule-table table-no-border">
          <thead>
            <tr className="table-head">
              <th>Sr. No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>Vehicle Number</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody>
              {Array.isArray(chauffeurListData?.data) &&
                chauffeurListData?.data.map((chauffeur: any, index: number) => {
                  const serialNumber = index + 1 + currentPage * 10;
                  return (
                    <>
                      <tr
                        className="table-body"
                        key={index}
                        style={{ margin: "10px 0" }}
                      >
                        <td style={{ paddingBottom: "10px" }}>
                          {serialNumber}
                        </td>
                        <td>{chauffeur?.name}</td>
                        <td>{chauffeur?.email_id}</td>
                        <td>{chauffeur?.mobile}</td>
                        <td>{chauffeur?.vehicle?.license_plate}</td>

                        {(window.location.hostname === 'www.gods-eye.in' || window.location.hostname === 'localhost')  ? (
                          <>
                            <td>
                              <Tooltip title="Adhoc Roster">
                                <IconButton>
                                  <MdAddLocationAlt
                                    onClick={() =>
                                      navigate(
                                        `/schedule-adhoc/${chauffeur?.user_id}`
                                      )
                                    }
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      marginRight: "10px",
                                      color: "grey",
                                    }}
                                  ></MdAddLocationAlt>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Update">
                                <IconButton>
                                  <FaUserEdit
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      marginRight: "10px",
                                      color: "grey",
                                    }}
                                    onClick={() =>
                                      navigate(
                                        `/chauffeur/${chauffeur?.user_id}`
                                      )
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton>
                                  <MdDelete
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      marginRight: "10px",
                                      color: "grey",
                                    }}
                                    onClick={() =>
                                      DeleteUserHandler(
                                        chauffeur?.user_id,
                                        chauffeur?.name
                                      )
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </>
                        ) : (
                          <>
                           <td>
                           <Tooltip title="View Chauffeur">
                              <IconButton>
                                <FaUserEdit
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginRight: "10px",
                                    color: "grey",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/viewChauffeur/${chauffeur?.user_id}`
                                    )
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                           </td>
                          </>
                        )}
                      </tr>
                    </>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={chauffeurListData?.totalRecords / 10}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}

export default ChauffeurList;
