import React, { useState, useEffect } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";

interface Route {
  path: string;
  name: string;
  icon: JSX.Element;
  subRoutes?: Route[];
  exact?: boolean;
}

interface SidebarMenuProps {
  route: Route;
  isOpen: boolean;
  showAnimation: Variants;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({ route, showAnimation, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);

  const menuAnimation: Variants = {
    hidden: {
      opacity: 0,
      height: 0,
      padding: 0,
      transition: { duration: 0.3, when: "afterChildren" },
    },
    show: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
        when: "beforeChildren",
      },
    },
  };

  const menuItemAnimation: Variants = {
    hidden: (i: number) => ({
      padding: 0,
      x: "-100%",
      transition: {
        duration: (i + 1) * 0.1,
      },
    }),
    show: (i: number) => ({
      x: 0,
      transition: {
        duration: (i + 1) * 0.1,
      },
    }),
  };

  return (
    <>
      <div className="menu" onClick={toggleMenu}>
        <div className="menu_item">
          <div className="icon" style={{ fontSize: '21px' }}>{route.icon}</div>
          {/* {isOpen && <div className="link_text">{route.name}</div>} */}
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="link_text"
              >
                {route.name}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <div>
            <FaAngleDown style={{ transform: isMenuOpen ? 'rotate(-90deg)' : 'rotate(0)' }} />
          </div>
        )}
      </div>
      {isMenuOpen && (
        <div className="menu_container">
          {route.subRoutes?.map((subRoute, i) => (
            <div key={i}>
              <NavLink to={subRoute.path} className="link">
                <div className="icon">{subRoute.icon}</div>
                <div className="link_text">{subRoute.name}</div>
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SidebarMenu;
