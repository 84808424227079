/** @format */

import React, { useState, useEffect } from "react";
import "../styledTable.css";
import { FaUserEdit } from "react-icons/fa";
import { deleteData, fetchData } from "../../api/apiService";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import { MdAddLocationAlt } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Loader from "../../components/utils/Loader";
import ReactPaginate from "react-paginate";
import { Dropdown, Input, InputGroup } from "rsuite";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import moment from "moment";
import { toast } from "react-toastify";

function UserList({ handleAddUser }: any) {
  const [userListData, setUserListData] = useState<any>();
  const [searchInput, setSearchInput] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(0); // Start from the first page
  const [loading, setLoading] = useState(true);
  const [searchBy, setSearchBy] = useState("mobile");
  const navigate = useNavigate();
  const styles = {
    width: 190,
    height: 35,
    // marginBottom: 10,
  };

  const fetchDataAndUpdateState = async (page: number) => {
    try {
      setLoading(true);
      let data;
      const pageSize = 10; // Number of entries per page
      const pageNumber = page + 1; // Convert zero-based index to one-based page number
  
      if (searchInput) {
        data = await fetchData(
          `user/admin/getAll?user_type=RIDER&page=${pageNumber}&pageSize=${pageSize}&${searchBy}=${searchInput}`
        );
      } else {
        data = await fetchData(
          `user/admin/getAll?user_type=RIDER&page=${pageNumber}&pageSize=${pageSize}`
        );
      }
      setUserListData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchDataAndUpdateState(currentPage);
  }, [searchInput, currentPage, searchBy]);

  const DeleteUserHandler = async (id: number, username: string) => {
    try {
      await deleteData(`user/admin/delete/${id}`);
      fetchDataAndUpdateState(currentPage);
      toast.success(`${username} deleted successfully`, {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user");
    }
  };

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };

  const handleDropdownSelect = (value: any, event: any) => {
    setSearchBy(value);
  };

  return (
    <>
      <div className="schedule-main">
        <div className="button-div">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
            }}
          >
            <Dropdown
              title={searchBy}
              onSelect={handleDropdownSelect}
              trigger="click"
              style={{
                marginRight: "10px",
                height: "100%",
                borderRadius: "10px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              <Dropdown.Item eventKey="mobile">Mobile</Dropdown.Item>
              <Dropdown.Item eventKey="email_id">Email</Dropdown.Item>
            </Dropdown>
            <InputGroup style={styles}>
              <Input
                onChange={(val) => setSearchInput(val)}
                placeholder="Search..."
              />
            </InputGroup>
          </div>
          {(window.location.hostname === 'www.gods-eye.in' || window.location.hostname === 'localhost')   ? (
            <button
              className="add_button"
              onClick={() => navigate("/users/add")}
            >
              Add User
            </button>
          ) : (
            <></>
          )}
        </div>
        <table className="schedule-table table-no-border">
          <thead>
            <tr className="table-head">
              <th>Sr. No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>Transport Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <>
            {loading ? (
              <Loader />
            ) : (
              <tbody>
                {Array.isArray(userListData?.data) &&
                  userListData?.data.map((user: any, index: number) => {
                    const serialNumber = index + 1 + currentPage * 10;
                    return (
                      <>
                        <tr
                          className="table-body"
                          key={index}
                          style={{ margin: "10px 0" }}
                        >
                         <td style={{ paddingBottom: "10px" }}>{serialNumber}</td>
                          <td>{user?.name}</td>

                          <td>{user?.email_id}</td>
                          <td>{user?.mobile}</td>

                          <td>{user?.transport_status}</td>
                          {(window.location.hostname === 'www.gods-eye.in' || window.location.hostname === 'localhost')   ? (
                            <>
                              <td>
                                <Tooltip title="Update">
                                  <IconButton>
                                    <FaUserEdit
                                      style={{
                                        height: "25px",
                                        width: "25px",
                                        marginRight: "10px",
                                        color: "grey",
                                      }}
                                      onClick={() =>
                                        navigate(`/users/${user?.user_id}`)
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Schedule">
                                  <IconButton>
                                    <MdAddLocationAlt
                                      style={{
                                        height: "25px",
                                        width: "25px",
                                        marginRight: "10px",
                                        color: "grey",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/schedule-user-rides/${user?.user_id}`
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton>
                                    <MdDelete
                                      style={{
                                        height: "25px",
                                        width: "25px",
                                        marginRight: "10px",
                                        color: "grey",
                                      }}
                                      onClick={() =>
                                        DeleteUserHandler(
                                          user?.user_id,
                                          user?.name
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <Tooltip title="View User">
                                  <IconButton>
                                    <FaUserEdit
                                      style={{
                                        height: "25px",
                                        width: "25px",
                                        marginRight: "10px",
                                        color: "grey",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/viewUser/${user?.user_id}`
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </>
                          )}
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            )}
          </>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(userListData?.totalRecords / 10)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}

export default UserList;
