import React, { useState, useEffect } from "react";
import { DatePicker } from "rsuite";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import "../../schedule/createRide/create_ride.css";
import {
  deleteData,
  deleteDataForRoster,
  fetchData,
} from "../../../api/apiService";
import AsyncSelect from "react-select/async";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../components/utils/Validations";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Chauffeur from "../../chaffeur/Chauffeur";
import FinalStepRepeatRoster from "./FinalStepRepeatRoster";
import { FaCalendar, FaClock } from "react-icons/fa";
import { toast } from "react-toastify";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";
dayjs.extend(customParseFormat);
interface FinalStepRepeatRosterProps {
  userData: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  onNext: () => void;
  handlePrev: () => void;
  userDetails: any;
  setUserDetails: any;
  chauffeurDetails: any;
  setChauffeurDetails: any;
  setStepOneUserDetails: any;
  setShownDetails: any;
  shownDetails: any;
  setNewUsers: any;
  stepOneUserDetails: any;
}

const RepeatRoster: React.FC<FinalStepRepeatRosterProps> = ({
  userData,
  setUserData,
  onNext,
  handlePrev,
  userDetails,
  setUserDetails,
  chauffeurDetails,
  setChauffeurDetails,
  setStepOneUserDetails,
  shownDetails,
  setShownDetails,
  // setNewUsers,
  stepOneUserDetails,
}) => {
  const [stepOneUserData, setStepOneUserData] = useState<any>();
  const [stepOneChauffeurData, setStepOneChauffeurData] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [pickupLocationData, setPickupLocationData] = useState<any>();
  const [dropoffLocationData, setDropoffLocationData] = useState<any>();
  const [newUsers, setNewUsers] = useState<any[]>([]);

  const param = useParams();

  const [rosterData, setRosterData] = useState({
    email_id: "",
    name: "",
    pickup_location: "",
    drop_location: "",
    pickup_time: "",
    dropoff_time: "",
    status: "",
    actual_pickup_time: "",
    actual_dropoff_time: "",
    past_scheduled_dates: [],
    upcoming_scheduled_dates: [],
    rider_roster_assignments: {
      trip_id: "",
      drop_location: "",
      dropoff_time: "",
      pickup_location: "",
      pickup_time: "",
    },
    chauffeur: {
      id: "",
      name: "",
      email_id: "",
      mobile: "",
    },
    vehicle_details: { license_plate: "" },
  });

  const fetchDataForRosterId = async () => {
    try {
      const rosterData = await fetchData(`admin/roster/${param?.slug}`);

      setRosterData(rosterData.roster);
      setNewUsers(rosterData.roster?.rider_roster_assignments);
      setChauffeurDetails(rosterData?.roster?.chauffeur);
      setStepOneUserDetails(rosterData.roster);
      console.log("repeartdata=a==>>>", rosterData);
    } catch (error) {}
  };
  useEffect(() => {
    fetchDataForRosterId();
  }, []);

  const DeleteUserHandlerInRoster = async (
    riderUserId: number,
    index: number
  ) => {
    try {
      const payload = {
        rider_user_id: riderUserId,
        status: "DELETED",
      };
      await deleteDataForRoster(`admin/roster/update/${param?.slug}`, payload);
      fetchDataForRosterId();
      toast.success(`User deleted successfully`, {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user");
    }
  };

  // const handleDeleteUser = async (riderUserId: number, index: number) => {
  //   try {
  //     await DeleteUserHandlerInRoster(riderUserId, param?.slug, index);
  //     // Fetch updated roster data
  //     fetchDataForRosterId();
  //     toast.success(`User deleted successfully`, {
  //       position: "top-right",
  //       autoClose: 500,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   } catch (error) {
  //     console.error("Error deleting user:", error);
  //     toast.error("Error deleting user");
  //   }
  // };

  // const handleNext = () => {
  //   setUserData({
  //     ...userData,
  //     users: {
  //       rider_user_id: stepOneUserData?.id,
  //     },
  //     chauffeur_user_id: rosterData?.chauffeur?.id,
  //     chauffeur: rosterData.chauffeur,
  //   });
  //   // setUserDetails(stepOneUserData, stepOneChauffeurData);
  //   setStepOneUserDetails([
  //     {
  //       ...setStepOneUserData,
  //       users: [
  //         {
  //           rider_user_id: stepOneUserData?.id,
  //           email_id: stepOneUserData?.email_id,
  //           name: stepOneUserData?.name,
  //           mobile: stepOneUserData?.mobile,
  //           organization: stepOneUserData?.employee_id,
  //         },
  //       ],
  //       dates_list: [startDate],
  //       chauffeur_user_id: rosterData?.chauffeur?.id,
  //     },
  //   ]);
  //   setShownDetails([
  //     ...shownDetails,
  //     {
  //       users: userDetails,
  //       chauffeur: chauffeurDetails,
  //     },
  //   ]);

  //   setNewUsers(stepOneUserDetails[0]?.users);

  //   onNext();
  // };

  const onChange = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
  };

  const handleNext = () => {
    const updatedUsers = newUsers.map((user: any, index: number) => {
      return {
        ...user,
        rider_user_id: user.rider_user_id,
        pickup_location: user.pickup_location || "",
        drop_location: user.drop_location || "",
      };
    });

    // Include chauffeur details
    const updatedChauffeurDetails = {
      id: chauffeurDetails.id,
      name: chauffeurDetails.name,
      email: chauffeurDetails.email,
      mobile: chauffeurDetails.mobile,
    };

    setUserData({
      users: updatedUsers,
      chauffeurDetails: updatedChauffeurDetails,
    });

    console.log("USerData____", updatedUsers);
    console.log("NewUser", newUsers);

    onNext();
  };

  const handleDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const { name, value } = e.target;
    // setRosterData({ ...rosterData, [name]: value });
  };

  const handleChauffeurDetailsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setChauffeurDetails({ ...chauffeurDetails, [name]: value });
  };

  const fetchDataAndUpdateMobileState = async (
    type: string,
    details: any,
    keyWord: any
  ) => {
    try {
      const data = await fetchData(
        `user/admin/search?user_type=${type}&mobile=${keyWord}`
      );
      if (type === "RIDER") {
        setStepOneUserData(data?.user);
        setUserDetails({ ...userDetails, email: data?.user?.email_id });
      } else if (type === "CHAUFFEUR") {
        setStepOneChauffeurData(data?.user);
        setChauffeurDetails({
          ...chauffeurDetails,
          email: data?.user?.email_id,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataAndUpdateEmailState = async (
    type: string,
    details: any,
    keyWord: any
  ) => {
    try {
      const data = await fetchData(
        `user/admin/search?user_type=${type}&email_id=${keyWord}`
      );
      if (type === "RIDER") {
        setStepOneUserData(data?.user);
        setUserDetails({ ...userDetails, mobile: data?.user?.mobile });
      } else if (type === "CHAUFFEUR") {
        setStepOneChauffeurData(data?.user);
        setChauffeurDetails({
          ...chauffeurDetails,
          mobile: data?.user?.mobile,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataAndUpdateStatePickup = async (inputValue?: any) => {
    try {
      let data;
      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setPickupLocationData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const loadOptions = async (inputValue: string, id: string) => {
    let locationData =
      id === "pickup" ? pickupLocationData : dropoffLocationData;

    if (!locationData) {
      // Fetch location data if not available
      await (id === "pickup"
        ? fetchDataAndUpdateStatePickup(inputValue)
        : fetchDataAndUpdateStateDropoff(inputValue));
      locationData = id === "pickup" ? pickupLocationData : dropoffLocationData;
    }

    const options = locationData.map((location: any) => ({
      value: location.formatted_address,
      label: location.formatted_address,
      location: location.location,
    }));

    return options;
  };

  const handleLocationChange = (
    id: string,
    selectedOption: any,
    index: number
  ) => {
    const locationData =
      id === "pickup" ? pickupLocationData : dropoffLocationData;

    const updatedUsers = [...newUsers];
    const currentUser = updatedUsers[index];

    if (id === "pickup") {
      updatedUsers[index] = {
        ...currentUser,
        pickup_location: selectedOption ? selectedOption.label : "",
      };
    } else if (id === "dropoff") {
      updatedUsers[index] = {
        ...currentUser,
        drop_location: selectedOption ? selectedOption.label : "",
      };
    }

    setNewUsers(updatedUsers);
  };

  const handleTimeChange = (value: any, index: any, type: any) => {
    const formattedTime = value;
    const updatedUsers = [...newUsers];
    if (type === "pickup") {
      updatedUsers[index] = {
        ...updatedUsers[index],
        pickup_time: formattedTime,
      };
    }

    if (type === "dropoff") {
      updatedUsers[index] = {
        ...updatedUsers[index],
        dropoff_time: formattedTime,
      };
    }

    setNewUsers(updatedUsers);
  };

  const fetchDataAndUpdateStateDropoff = async (inputValue?: any) => {
    try {
      let data;
      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setDropoffLocationData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (validatePhoneNumber(userDetails?.mobile)) {
      fetchDataAndUpdateMobileState("RIDER", userDetails, userDetails?.mobile);
    }
    if (validatePhoneNumber(chauffeurDetails?.mobile)) {
      fetchDataAndUpdateMobileState(
        "CHAUFFEUR",
        chauffeurDetails,
        chauffeurDetails?.mobile
      );
    }
  }, [userDetails?.mobile, chauffeurDetails?.mobile]);

  useEffect(() => {
    if (validateEmail(userDetails?.email)) {
      fetchDataAndUpdateEmailState("RIDER", userDetails, userDetails?.email);
    }
    if (validateEmail(chauffeurDetails?.email)) {
      fetchDataAndUpdateEmailState(
        "CHAUFFEUR",
        chauffeurDetails,
        chauffeurDetails?.email
      );
    }
  }, [userDetails?.email, chauffeurDetails?.email]);

  return (
    <div className="roster-main">
      {Array.isArray(rosterData?.rider_roster_assignments) &&
        rosterData?.rider_roster_assignments.map((user, index) => (
          <div className="roster-input-div-main" key={index}>
            <h6 className="pragraph-heading">User Details {index + 1}</h6>
            <Row>
              <Col>
                <div className="roster-input-div">
                  <p>Name :</p>
                  <input
                    type="text"
                    name="Name"
                    value={user.name}
                    placeholder="Name"
                  />
                </div>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>User's Email:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="email_id"
                    defaultValue={user?.email_id}
                    placeholder="Enter Email Id"
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <div className="roster-input-div">
                  <p>Phone :</p>
                  <input
                    type="text"
                    name="mobile"
                    value={user.mobile}
                    onChange={handleDetailsChange}
                    placeholder="Phone Number"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <p style={{ margin: "6px 0" }}>Pickup location :</p>
                  <AsyncSelect
                    defaultValue={{
                      value: user.pickup_location,
                      label: user.pickup_location,
                    }}
                    className="step_two_input"
                    cacheOptions
                    required
                    id="pickup_location"
                    defaultOptions
                    loadOptions={(inputValue) =>
                      loadOptions(inputValue, "pickup")
                    }
                    onChange={(selectedOption) =>
                      handleLocationChange("pickup", selectedOption, index)
                    }
                    onInputChange={(inputValue) =>
                      fetchDataAndUpdateStatePickup(inputValue)
                    }
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <p style={{ margin: "6px 0" }}>DropOff location :</p>

                  <AsyncSelect
                    className="step_two_input"
                    cacheOptions
                    required
                    id="drop_location"
                    defaultOptions
                    defaultValue={{
                      value: user.drop_location,
                      label: user.drop_location,
                    }}
                    // value={user?.drop_location}
                    loadOptions={(inputValue) =>
                      loadOptions(inputValue, "dropoff")
                    }
                    onChange={(selectedOption) =>
                      handleLocationChange("dropoff", selectedOption, index)
                    }
                    onInputChange={(inputValue) =>
                      fetchDataAndUpdateStateDropoff(inputValue)
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div>
                  <p style={{ margin: "6px 0" }}>Pickup Time:</p>
                  {/* <h1>{user?.pickup_time.toLocaleString('en-US', {timeZone: 'Asia/Kolkata'})}</h1>
                  <h1>{new Date(user?.pickup_time.toLocaleString('en-US', {timeZone: 'Asia/Kolkata'}))}</h1> */}
                  <DatePicker
                    name="pickup_time"
                    // value={user?.pickup_time}
                    // onChange={(e: any) => handleTimeChange(e, index, "pickup")}
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    placeholder="Pickup Time"
                    style={{ width: "80%" }}
                    defaultValue={new Date(user?.pickup_time)}
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <p style={{ margin: "6px 0" }}>DropOff Time :</p>
                  <DatePicker
                    value={user?.pickup}
                    name="dropoff_time"
                    onChange={(e: any) => handleTimeChange(e, index, "dropoff")}
                    format="HH:mm:ss"
                    caretAs={FaClock}
                    placeholder="Drop Time"
                    style={{ width: "80%" }}
                    defaultValue={new Date(user?.dropoff_time)}
                  />
                </div>
              </Col>
            </Row>
            {/* Add a delete button */}
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <button
                className="btn btn-danger"
                onClick={() =>
                  DeleteUserHandlerInRoster(user.rider_user_id, index)
                }
              >
                Delete
              </button>
            </div>
          </div>
        ))}

      <div className="roster-input-div-main">
        <p className="pragraph-heading">Chauffeur Details</p>
        <Row>
          <Col>
            <div className="roster-input-div">
              <p>Name :</p>
              <input
                type="text"
                name="Name"
                value={rosterData?.chauffeur?.name}
                placeholder="Name"
              />
            </div>
            <div className="roster-input-div">
              <p>Phone :</p>
              <input
                type="text"
                name="mobile"
                value={rosterData?.chauffeur?.mobile}
                onChange={handleChauffeurDetailsChange}
                placeholder="Phone Number"
              />
            </div>
          </Col>
          <Col>
            <div className="roster-input-div">
              <p>Email :</p>
              <input
                type="text"
                name="email"
                value={rosterData?.chauffeur?.email_id}
                onChange={handleChauffeurDetailsChange}
                placeholder="Email Address"
              />
            </div>
            <div className="roster-input-div">
              <p>Vehicle Number :</p>
              <input
                type="text"
                name="vehicle"
                value={rosterData?.vehicle_details?.license_plate || ""}
                placeholder="Vehicle Number"
              />
            </div>
          </Col>
        </Row>
        <div className="roster-input-div">
          <p style={{ marginTop: "7px" }}>Past Scheduled Dates :</p>
          <input
            type="text"
            name="vehicle"
            value={rosterData?.past_scheduled_dates || ""}
            placeholder="You will see past scheduled dated here(if any)"
          />
        </div>
        <div className="roster-input-div">
          <p style={{ marginTop: "7px" }}>Upcoming Scheduled Dates :</p>
          <input
            type="text"
            name="vehicle"
            value={rosterData?.upcoming_scheduled_dates || ""}
            placeholder="You will see upcoming scheduled dated here(if any)"
          />
        </div>
      </div>

      <div>
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn "
            style={{
              width: "90px",
              backgroundColor: "lightgray",
            }}
            disabled
            onClick={handlePrev}
          >
            Previous
          </button>
          <button
            style={{ width: "90px", marginLeft: "10px" }}
            onClick={handleNext}
            type="submit"
            className="btn btn-success"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default RepeatRoster;
