import React, { useState } from "react";
import Logo from "../assets/logo.png";
import AddChauffeur from "./AddChauffeur";
import Header from "../../components/header/Header";
import ChauffeurList from "./ChauffeurList";

const Chauffeur = () => {
  const [addUser, setAddUser] = useState(false);
  const handleAddUser = () => {
    setAddUser(!addUser);
  };
  return (
    <>
      <Header />

      {/* {addUser == true ? <AddChauffeur /> : */}
      <ChauffeurList handleAddUser={handleAddUser} />
      {/* } */}
    </>
  );
};

export default Chauffeur;
