import React, { useState } from "react";
// import {
//     HeaderContainer,
//     Logo,
//     Navbar,
//     Sidebar,
//     MenuToggle,
//     MenuIcon,
//   } from './HeaderStyles';
import "./header.css";
import Notification from "../../assets/Notification.svg";
const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="header">
      <div className="header-container">
        {/* <h1>CRM Company</h1> */}
        <div className="custom-select">
          <p className="location_heading">Location:</p>
          <select defaultValue="delhi">
            <option value="" disabled selected>
              Select Location
            </option>
            <option value="delhi">Delhi NCR</option>
            <option value="hyderabad">Hyderabad</option>
            <option value="bangalore">Bangalore</option>
            <option value="mumbai">Mumbai</option>
            <option value="pune">Pune</option>

            {/* <option value="option2">Option 2</option>
            <option value="option3">Option 3</option> */}
          </select>
        </div>
        <div>
          <div className="notification-div">
            <img src={Notification} alt="Notification" />
          </div>
          {/* <p className="notification-number">2</p> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
