// utils/Validation.ts

export const validatePhoneNumber = (phone: string): boolean => {
  const pattern = /^\d{10,11}$/;
  return pattern.test(phone);
};

export const validateEmail = (email: string): boolean => {
  // Add email validation logic
  return /\S+@\S+\.\S+/.test(email);
};

export const validatePassword = (password: string): boolean => {
  // Add password validation logic (e.g., minimum length, special characters)
  return password.length >= 8;
};
