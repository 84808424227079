import React, { useEffect, useState } from "react";
import "./AddUser.css";
import Header from "../../components/header/Header";
import {
  fetchData,
  postFormData,
  PutDataWithImage,
} from "../../api/apiService";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";
import { useError } from "../../components/utils/error/ErrorContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import CloseIcon from "@mui/icons-material/Close";
import { Row, Col } from "react-bootstrap";

const AddChauffeur = () => {
  const navigate = useNavigate();
  const { showError } = useError();
  const [selectedImage, setSelectedImage] = useState();
  const [locationData, setLocationData] = useState<any>();
  const [imagePreview, setImagePreview] = useState<any>();
  const [formData, setFormData] = useState({
    email_id: "abc2@gmail.com",
    mobile: "7974103505",
    name: "Sourabh Majumdar",
    image: null,
    latitude: "12.9716",
    longitude: "77.5946",
    user_type: "CHAUFFEUR",
    city: "Bangalore",
    emergency_contact: "2342311929",
    home_address: " ",
    work_address: " ",
    user_details: {
      driving_license: " ",
      adhaar: "AKAJLJFLA",
    },
    vehicle: {
      type: "Sedan",
      license_plate: "KA-01-1231",
    },
    license_plate: "KA-01-1231",
    type: "",
    driving_license: "",
    adhaar: "",
    employee_id: "DAFTAR-12311",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      // Create FormData object
      const formDataObject = new FormData();
      formDataObject.append("email_id", formData.email_id);
      formDataObject.append("mobile", formData.mobile);
      formDataObject.append("name", formData.name);
      formDataObject.append("user_type", formData.user_type);
      formDataObject.append("emergency_contact", formData.emergency_contact);
      formDataObject.append("home_address", formData.home_address);
      formDataObject.append("work_address", formData.work_address);
      formDataObject.append("user_details[adhaar]", formData.adhaar);
      formDataObject.append("vehicle[type]", formData.vehicle.type);
      formDataObject.append(
        "user_details[driving_license]",
        formData.user_details.driving_license
      );

      formDataObject.append("vehicle[license_plate]", formData.license_plate);

      if (selectedImage) {
        formDataObject.append("image", selectedImage);
      }
      formDataObject.append("organization", "DAFTAR");
      formDataObject.append("employee_id", formData.employee_id);
      formDataObject.append("city", formData.city);
      formDataObject.append("longitude", formData.longitude);
      formDataObject.append("latitude", formData.latitude);
      await postFormData("user/admin/create", formDataObject);
      toast.success("Chauffeur added successfully", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setTimeout(() => {
        navigate("/chauffeur");
      }, 1000);
    } catch (error: any) {
      if (error.isAxiosError && error.response) {
        const statusCode = error.response.status;
        const errorMsg = error.response.data.error;
        toast.error(`HTTP Error ${statusCode}: ${errorMsg || "Unknown error"}`);
      } else {
        showError(`An error occurred: ${error.message || "Unknown error"}`);
      }
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      vehicle: {
        ...prevFormData.vehicle,
        [name]: value,
      },
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Check if the input is the mobile field and the entered value is not exactly 10 digits
    if (name === "mobile" && value.length > 10) {
      // Show an error message or handle the error as needed
      showError("Mobile number should have exactly 10 digits");
      return; // Do not update the state further
    }

    if (name === "emergency_contact" && value.length > 10) {
      // Show an error message or handle the error as needed
      showError("Emergency number should have exactly 10 digits");
      return; // Do not update the state further
    }

    // Check if the input is the Aadhaar card number field and the entered value is greater than 12 characters
    if (name === "adhaar" && value.length > 12) {
      // Show an error message or handle the error as needed
      showError("Aadhaar card number should not exceed 12 characters");
      return; // Do not update the state further
    }

    if (name === "driving_license") {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        user_details: {
          ...prevFormData.user_details,
          driving_license: value,
        },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const fetchDataAndUpdateState = async (inputValue?: any) => {
    try {
      let data;

      data = await fetchData(`map/place/textSearch?query=${inputValue}`);
      setLocationData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataAndUpdateState();
  }, [formData.home_address, formData.work_address]);

  const loadOptions = async (inputValue: string) => {
    if (inputValue) {
      await fetchDataAndUpdateState(inputValue);
      const options = locationData.map((location: any) => ({
        value: location.formatted_address,
        label: location.formatted_address,
        location: location.location,
      }));
      return options;
    } else {
      return [];
    }
  };

  const handleLocationChange = (id: string, selectedOption: any) => {
    if (id === "work") {
      setFormData({
        ...formData,
        work_address: selectedOption ? selectedOption.label : "",
      });
    } else if (id === "home") {
      setFormData({
        ...formData,
        home_address: selectedOption ? selectedOption.label : "",
      });
    }
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      // Validate image dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const maxWidth = 250;
        const maxHeight = 250;
        const minWidth = 15;
        const minHeight = 50;

        if (
          img.width <= maxWidth &&
          img.height <= maxHeight &&
          img.width >= minWidth &&
          img.height >= minHeight
        ) {
          // Set selectedImage state
          setSelectedImage(file);
          setFormData({
            ...formData,
            image: file,
          });

          // Display image preview
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          // Display error message for invalid dimensions
          toast.error(
            `Please upload an image with dimensions between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight}`
          );

          // Clear the file input value
          const fileInput = document.getElementById(
            "imageInput"
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }

          // Clear selectedImage and imagePreview in state
          setSelectedImage(undefined);
          setImagePreview(null);
        }
      };
    }
  };

  const handleRemoveImage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSelectedImage(undefined); // Pass undefined instead of null
    setImagePreview(null);

    // Clear the file input value
    const fileInput = document.getElementById("imageInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ""; // Set the value to an empty string to clear the input
    }
  };

  const navigateToUserlist = () => {
    navigate("/chauffeur");
  };

  return (
    <>
      <Header />

      <Box m="20px">
        <Box>
          <h3 className="page-title">Create Chauffeur</h3>
        </Box>
        <Card className="add_card">
          <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="name"
                    placeholder="Enter your name"
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="email_id"
                    placeholder="Enter Email Id"
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="mobile"
                    placeholder="Enter Phone Number"
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Emergency Phone Number:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="emergency_contact"
                    placeholder="Enter Emergency Phone Number"
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Aadhaar Card Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="adhaar"
                    required
                    placeholder="Enter Aadhar Card Number"
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Employee_id</Form.Label>
                  <Form.Control
                    type="text"
                    name="employee_id"
                    required
                    placeholder="Enter Employee Id"
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Image:</Form.Label>
              <Form.Control
                type="file"
                accept="images/*"
                id="imageInput"
                onChange={handleImageChange}
              />
            </Form.Group>
            {imagePreview && (
              <div
                style={{ position: "relative" }}
                onClick={(e) => {
                  handleRemoveImage(e);
                }}
              >
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    height: "100px",
                    width: "100px",
                    margin: "10px",
                  }}
                />
                <CloseIcon
                  fontSize="small"
                  color="warning"
                  style={{
                    position: "absolute",
                    left: "99px",
                  }}
                />
              </div>
            )}

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Address(Work):</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    id="work"
                    placeholder="Select Work Address"
                    defaultOptions
                    loadOptions={(inputValue) => loadOptions(inputValue)}
                    onChange={(selectedOption) =>
                      handleLocationChange("work", selectedOption)
                    }
                    className="address_input"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Address(Home):</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    id="home"
                    placeholder="Select Home Address"
                    defaultOptions
                    loadOptions={(inputValue) => loadOptions(inputValue)}
                    onChange={(selectedOption) =>
                      handleLocationChange("home", selectedOption)
                    }
                    className="address_input"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Driving License Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="driving_license"
                    required
                    placeholder="Enter Driving License Number"
                    onChange={handleInputChange}
                    // onChange={(e) => handleInputChange(e, "user_details")}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Vehicle Registration</Form.Label>
                  <Form.Control
                    type="text"
                    name="license_plate"
                    required
                    placeholder="Enter Vehicle Registration"
                    onChange={handleInputChange}
                    className="new_form_control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Choose Vehicle Type</Form.Label>
                  <Form.Select
                    name="type" // Add name attribute
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                  >
                    <option>Select Vehicle Type</option>
                    <option value="Sedan">Sedan</option>
                    <option value="MUV">MUV</option>
                    <option value="Hatchback">Hatchback</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <div>
              <button
                style={{ width: "90px" }}
                type="submit"
                className="btn btn-success"
              >
                Save
              </button>

              <button
                type="button"
                className="btn "
                style={{
                  width: "90px",
                  backgroundColor: "lightgray",
                  marginLeft: "10px",
                }}
                onClick={navigateToUserlist}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Card>
      </Box>
    </>
  );
};

export default AddChauffeur;
