import React, { useState } from "react";
import Logo from "../assets/logo.png";
import AddUser from "./AddUser";
import Header from "../../components/header/Header";
import UserList from "./UserList";

const Users = () => {
  const [addUser, setAddUser] = useState(false);
  const handleAddUser = () =>{
    setAddUser(!addUser);
  }
  return (
    <>
      <Header />

      {/* {addUser == true ? <AddUser /> :  */}
      <UserList handleAddUser={handleAddUser} />
      {/* } */}
    </>
  );
};

export default Users;
