import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./create_ride.css";
import { fetchData } from "../../../api/apiService";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../components/utils/Validations";
import { Row, Col } from "react-bootstrap";

interface StepOneProps {
  userData: any;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  onNext: () => void;
  handlePrev: () => void;
  userDetails: any;
  setUserDetails: any;
  chauffeurDetails: any;
  setChauffeurDetails: any;
  setStepOneUserDetails: any;
  setShownDetails: any;
  shownDetails: any;
  setNewUsers: any;
  stepOneUserDetails: any;
}

const StepOne: React.FC<StepOneProps> = ({
  userData,
  setUserData,
  onNext,
  handlePrev,
  userDetails,
  setUserDetails,
  chauffeurDetails,
  setChauffeurDetails,
  setStepOneUserDetails,
  shownDetails,
  setShownDetails,
  setNewUsers,
  stepOneUserDetails,
}) => {
  const [stepOneUserData, setStepOneUserData] = useState<any>();
  const [stepOneChauffeurData, setStepOneChauffeurData] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const handleDayClick = (date: Date) => {
    const selectedIndex = selectedDates.findIndex(
      (selectedDate) => selectedDate.getTime() === date.getTime()
    );
    const newSelectedDates = [...selectedDates];
    if (selectedIndex === -1) {
      newSelectedDates.push(date);
    } else {
      newSelectedDates.splice(selectedIndex, 1);
    }
    setSelectedDates(newSelectedDates);
  };

  console.log("sleceteddates==>", selectedDates);

  // const handleNext = () => {
  //   setUserData({
  //     ...userData,
  //     users: {
  //       rider_user_id: stepOneUserData?.id,
  //     },
  //     chauffeur_user_id: stepOneChauffeurData?.id,
  //     chauffeur: stepOneChauffeurData,
  //   });
  //   // setUserDetails(stepOneUserData, stepOneChauffeurData);
  //   setStepOneUserDetails([
  //     {
  //       ...setStepOneUserData,
  //       users: [
  //         {
  //           rider_user_id: stepOneUserData?.id,
  //           email_id: stepOneUserData?.email_id,
  //           name: stepOneUserData?.name,
  //           mobile: stepOneUserData?.mobile,
  //           organization: stepOneUserData?.employee_id,
  //         },
  //       ],
  //       dates_list: [startDate],
  //       chauffeur_user_id: stepOneChauffeurData?.id,
  //     },
  //   ]);
  //   setShownDetails([
  //     ...shownDetails,
  //     {
  //       users: userDetails, // Users' data object
  //       chauffeur: chauffeurDetails, // Update chauffeur details
  //     },
  //   ]);

  //   setNewUsers(stepOneUserDetails[0]?.users);

  //   onNext();
  // };

  const handleNext = () => {
    const datesList = selectedDates.length > 0 ? selectedDates : [new Date()]; // Use selected dates if available, otherwise default to current date
    setUserData({
      ...userData,
      users: {
        rider_user_id: stepOneUserData?.id,
      },
      chauffeur_user_id: stepOneChauffeurData?.id,
      chauffeur: stepOneChauffeurData,
    });
  
    setStepOneUserDetails([
      {
        ...setStepOneUserData,
        users: [
          {
            rider_user_id: stepOneUserData?.id,
            email_id: stepOneUserData?.email_id,
            name: stepOneUserData?.name,
            mobile: stepOneUserData?.mobile,
            organization: stepOneUserData?.employee_id,
          },
        ],
        dates_list: datesList, // Include selected dates here or default to current date
        chauffeur_user_id: stepOneChauffeurData?.id,
      },
    ]);
  
    setShownDetails([
      ...shownDetails,
      {
        users: userDetails, // Users' data object
        chauffeur: chauffeurDetails, // Update chauffeur details
      },
    ]);
  
    setNewUsers(stepOneUserDetails[0]?.users);
  
    onNext();
  };
  

  const handleDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleChauffeurDetailsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setChauffeurDetails({ ...chauffeurDetails, [name]: value });
  };

  const fetchDataAndUpdateMobileState = async (
    type: string,
    details: any,
    keyWord: any
  ) => {
    try {
      const data = await fetchData(
        `user/admin/search?user_type=${type}&mobile=${keyWord}`
      );
      if (type === "RIDER") {
        setStepOneUserData(data?.user);
        setUserDetails({ ...userDetails, email: data?.user?.email_id });
      } else if (type === "CHAUFFEUR") {
        setStepOneChauffeurData(data?.user);
        setChauffeurDetails({
          ...chauffeurDetails,
          email: data?.user?.email_id,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataAndUpdateEmailState = async (
    type: string,
    details: any,
    keyWord: any
  ) => {
    try {
      const data = await fetchData(
        `user/admin/search?user_type=${type}&email_id=${keyWord}`
      );
      if (type === "RIDER") {
        setStepOneUserData(data?.user);
        setUserDetails({ ...userDetails, mobile: data?.user?.mobile });
      } else if (type === "CHAUFFEUR") {
        setStepOneChauffeurData(data?.user);
        setChauffeurDetails({
          ...chauffeurDetails,
          mobile: data?.user?.mobile,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (validatePhoneNumber(userDetails?.mobile)) {
      fetchDataAndUpdateMobileState("RIDER", userDetails, userDetails?.mobile);
    }
    if (validatePhoneNumber(chauffeurDetails?.mobile)) {
      fetchDataAndUpdateMobileState(
        "CHAUFFEUR",
        chauffeurDetails,
        chauffeurDetails?.mobile
      );
    }
  }, [userDetails?.mobile, chauffeurDetails?.mobile]);

  useEffect(() => {
    if (validateEmail(userDetails?.email)) {
      fetchDataAndUpdateEmailState("RIDER", userDetails, userDetails?.email);
    }
    if (validateEmail(chauffeurDetails?.email)) {
      fetchDataAndUpdateEmailState(
        "CHAUFFEUR",
        chauffeurDetails,
        chauffeurDetails?.email
      );
    }
  }, [userDetails?.email, chauffeurDetails?.email]);

  return (
    <div className="roster-main">
      <div className="roster-input-div-main">
        <h6 className="pragraph-heading">User Details</h6>
        <Row>
          <Col>
            <div className="roster-input-div">
              <p>Name :</p>
              <input
                type="text"
                name="Name"
                value={stepOneUserData?.name}
                placeholder="Name"
                // disabled
                // onChange={(e) => fetchDataAndUpdateState(e, setUserDetails)}
              />
            </div>
            <div className="roster-input-div">
              <p>Phone :</p>
              <input
                type="text"
                name="mobile"
                value={userDetails?.mobile}
                onChange={handleDetailsChange}
                placeholder="Phone Number"
              />
            </div>
          </Col>
          <Col>
            <div className="roster-input-div">
              <p>Email :</p>
              <input
                type="text"
                name="email"
                value={userDetails?.email}
                onChange={handleDetailsChange}
                placeholder="Email Address"
              />
            </div>
            <div className="roster-input-div">
              <p>Organization :</p>
              <input
                type="text"
                name="mobile"
                value={stepOneUserData?.employee_id}
                placeholder="Organization"
                // onChange={handleUserDetailsChange}
                // disabled
              />
            </div>
          </Col>
        </Row>
      </div>
      {/* <div className="roster-input-div-main">
        <p className="pragraph-heading">Chauffeur Details</p>

        <div className="roster-input-div">
          <input
            type="text"
            name="mobile"
            value={chauffeurDetails?.mobile}
            onChange={handleChauffeurDetailsChange}
            placeholder="Phone Number"
          />
          <input
            type="text"
            name="Name"
            value={stepOneChauffeurData?.name}
            placeholder="Name"
            // onChange={handleChauffeurDetailsChange}
            // disabled
            // style={{
            //   border: "2px solid #d9d9d940",
            //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            // }}
          />
        </div>

        <div className="roster-input-div">
          <input
            type="text"
            name="email"
            value={chauffeurDetails?.email}
            onChange={handleChauffeurDetailsChange}
            placeholder="Email Address"
          />
          <input
            type="text"
            name="vehicle"
            value={stepOneChauffeurData?.vehicle?.license_plate || ""}
            placeholder="Vehicle Number"
            // disabled
            // onChange={handleChauffeurDetailsChange}
          />
        </div>
        <DatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
        />
      </div> */}
      <div className="roster-input-div-main">
        <p className="pragraph-heading">Chauffeur Details</p>
        <Row>
          <Col>
            <div className="roster-input-div">
              <p>Name :</p>
              <input
                type="text"
                name="Name"
                value={stepOneChauffeurData?.name}
                placeholder="Name"
              />
            </div>
            <div className="roster-input-div">
              <p>Phone :</p>
              <input
                type="text"
                name="mobile"
                value={chauffeurDetails?.mobile}
                onChange={handleChauffeurDetailsChange}
                placeholder="Phone Number"
              />
            </div>
          </Col>
          <Col>
            <div className="roster-input-div">
              <p>Email :</p>
              <input
                type="text"
                name="email"
                value={chauffeurDetails?.email}
                onChange={handleChauffeurDetailsChange}
                placeholder="Email Address"
              />
            </div>
            <div className="roster-input-div">
              <p>Vehicle Number :</p>
              <input
                type="text"
                name="vehicle"
                value={stepOneChauffeurData?.vehicle?.license_plate || ""}
                placeholder="Vehicle Number"
                // disabled
                // onChange={handleChauffeurDetailsChange}
              />
            </div>
          </Col>
        </Row>
        <div className="roster-input-div">
          <p style={{ marginTop: "7px" }}>Date :</p>
          <DayPicker
            selected={selectedDates.length > 0 ? selectedDates : new Date()} 
            modifiers={{
              disabled: { before: new Date() }, // Disable dates before today
            }}
            onDayClick={handleDayClick}
          />
        </div>
      </div>

      <div
      // style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {/* <button onClick={handlePrev} disabled className="Button-Custom">
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={!(stepOneUserDetails && stepOneChauffeurData)}
          className="Button-Custom"
        >
          Next
        </button> */}
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn "
            style={{
              width: "90px",
              backgroundColor: "lightgray",
            }}
            disabled
            onClick={handlePrev}
            // onClick={navigateToUserlist}
          >
            Previous
          </button>
          <button
            style={{ width: "90px", marginLeft: "10px" }}
            onClick={handleNext}
            disabled={!(stepOneUserDetails && stepOneChauffeurData)}
            type="submit"
            className="btn btn-success"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
